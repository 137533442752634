import React, {useState} from "react";
import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";
import {useToast} from "../../contexts/ToastContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useLanguage} from "../../contexts/LanguageContext";
import {X} from "lucide-react";

export const AddAccountsModal = () => {
    const {isOpen, onClose, type, data} = useModal();

    const isModalOpen = isOpen && type === "add-accounts";

    const {add} = useToast();
    const {translate} = useLanguage();

    const [file, setFile] = useState<File | null>(null);
    const [form, setForm] = useState({
        parse_pattern: '',
        change_recovery: false,
        encode: true,
        locale: {
            country: 'ua',
            language: 'uk',
        }
    });

    const axiosPrivate = useAxiosPrivate();

    const typeParse = ['login', 'password', 'recovery_email', 'recovery_password', 'recovery_password_imap', 'phone'];

    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<Record<string, { value: string }>>({});
    const [errorResponse, setErrorResponse] = useState<string | null>(null);

    //TODO load locales and add select it

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0] || null;
        setFile(selectedFile);
    };

    const handleAddPattern = (item: string) => {
        if (form.parse_pattern.includes(item + ':')) {
            setForm(prev => ({...prev, parse_pattern: prev.parse_pattern.replace(item + ':', '')}));
        } else {
            setForm(prev => ({...prev, parse_pattern: prev.parse_pattern + item + ':'}));
        }
    }

    const clearPattern = () => {
        setForm(prev => ({...prev, parse_pattern: ''}));
    }

    const handlerAddAccounts = () => {
        setError({});
        setLoading(true);

        const formData = new FormData();

        let hasError = false;

        if (!form.locale) {
            setError(prev => ({...prev, appId: {value: 'one_keyword_is_required'}}));
            hasError = true;
        }

        if (!file) {
            setError(prev => ({...prev, file: {value: 'file_is_required'}}));
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        formData.append('body', JSON.stringify(form));
        formData.append('file', file!!);

        axiosPrivate.post('/accounts/add',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((response) => {
            if (response.status === 200) {
                add({
                    type: "success",
                    duration: 5000,
                    message: "Accounts added successfully",
                });
                onClose();
                data.success = true;
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {translate('accounts.list.modal.title')}
                    </DialogTitle>
                </DialogHeader>

                <div className={"flex flex-col p-2 gap-2"}>

                    <span
                        className={"text-xs font-bold text-gray-dark dark:text-white"}>{translate('accounts.list.modal.select-structure-line')}</span>
                    <div className={"grid grid-cols-3 gap-1"}>
                        {typeParse.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleAddPattern(item)}
                                className={`flex flex-row p-1 rounded-md ${form.parse_pattern.includes(item + ':') ? 'bg-gray/50' : 'bg-green/10 hover:bg-green/50'} cursor-pointer transition-all items-center justify-between`}
                            >
                                <span
                                    className={"text-xs text-gray-dark dark:text-white"}
                                >
                                    {item}
                                </span>
                            </div>
                        ))}
                    </div>
                    <span
                        className={"text-xs font-bold text-gray-dark dark:text-white"}>{translate('accounts.list.modal.parse-pattern')}</span>
                    <div className={"h-8 flex w-full flex-row p-1 rounded-md bg-gray/50 items-center justify-between overflow-scroll"}>
                        <div className={"flex flex-row w-max-[100px]"}>
                            <p className={"text-xs font-bold text-green/50 whitespace"}>{form.parse_pattern}</p>
                        </div>
                        {form.parse_pattern.length > 0 &&
                            <div className={"flex flex-row p-1 bg-red/10 hover:bg-red/50 rounded-md cursor-pointer"}>
                                <X onClick={clearPattern} size={12} className={"text-gray-dark dark:text-white"}/>
                            </div>
                        }
                    </div>

                    <div className={"flex flex-row"}>
                        <input
                            className={"text-xs text-gray-dark dark:text-white cursor-pointer"}
                            type={"file"}
                            multiple={false}
                            accept={".txt"}
                            onChange={handleFileChange}/>
                    </div>

                    {errorResponse && <span className={"text-xs text-red-500"}>{errorResponse}</span>}
                </div>

                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button onClick={handlerAddAccounts} disabled={loading}>
                        {translate('accounts.list.modal.upload')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}