import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";

export const DeleteModal = () => {
    const {isOpen, onClose, type, callback, data} = useModal();

    const isModalOpen = isOpen && type === "delete-universal";

    if (!data.deleteData) {
        return null;
    }

    const handlerCallbackClick = () => {
        callback && callback.onConfirm && callback.onConfirm();
        onClose();
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0 overflow-hidden">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-2xl text-center font-bold text-gray-dark dark:text-white">
                        {data.deleteData!!.title}
                    </DialogTitle>
                    {data.deleteData!!.description && data.deleteData!!.description.length > 0 &&
                        <DialogDescription className="text-center text-gray-dark dark:text-white">
                            {data.deleteData!!.description}
                        </DialogDescription>
                    }
                </DialogHeader>

                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button onClick={handleClose}>
                        {data.deleteData!!.button_close}
                    </Button>
                    <Button className="bg-red/50 hover:bg-red/90" onClick={handlerCallbackClick}>
                        {data.deleteData!!.button_delete}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
