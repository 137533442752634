import {SmsCountryData} from "../../lib/transform-json";
import React from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {ActionTooltip} from "../../components/shared/tooltip";
import {Trash} from "lucide-react";
import {Switch} from "../../components/shared/switch";

type SmsCountryItemProp = {
    data: SmsCountryData;
    onDelete?: (id: string) => void;
    onSwitch?: (is_active: boolean, id: string) => void;
}

const SmsCountryItem: React.FC<SmsCountryItemProp> = ({data, onDelete, onSwitch}) => {

    const {translate} = useLanguage();

    const handleSwitch = () => {
        onSwitch && onSwitch(!data.is_active, data.id.toString());
    }

    return (
        <div
            className="flex flex-col rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border">
            <div
                className={`flex flex-row w-full ${data.is_active ? "bg-green/10" : "bg-orange/10"} justify-between items-center p-2`}>

                <div className={"flex flex-row gap-2 items-center"}>
                     <span
                         className={"text-xs text-gray-dark dark:text-white font-bold"}>{data.service.type}</span>
                </div>

                <div className={"flex flex-row gap-1"}>
                    <ActionTooltip label={"Switch active"}>
                        <div
                            className={"cursor-pointer flex p-1 rounded-md"}
                        >
                            <Switch label={translate('sms-service.service.on-off')} value={data.is_active} onChange={handleSwitch}/>
                        </div>
                    </ActionTooltip>
                </div>
            </div>
            <div className={"flex flex-row gap-3 p-2 items-center m-2"}>
                <img
                    className={`h-8 w-12 ms-1 rounded-md overflow-hidden ${data.locale.type === 'TEST' ? 'grayscale' : ''}`}
                    alt={data.locale.country.toUpperCase()}
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${data.locale.country.toUpperCase()}.svg`}/>

                <div className={"flex flex-row justify-between items-center w-full"}>
                    <div className={"flex flex-col gap-2"}>
                        <div className={"flex flex-row gap-2"}>
                            <span className={"text-xs text-gray font-bold"}>Country:</span>
                            <span
                                className={"text-xs text-gray-dark dark:text-white font-bold"}>{data.locale.country.toUpperCase()}</span>
                        </div>
                        <div className={"flex flex-row gap-2"}>
                            <span className={"text-xs text-gray font-bold"}>Language:</span>
                            <span
                                className={"text-xs text-gray-dark dark:text-white font-bold"}>{data.locale.language.toUpperCase()}</span>
                        </div>

                        <div className={"flex flex-row gap-2"}>
                            <span className={"text-xs text-gray font-bold"}>Service country code:</span>
                            <span
                                className={"text-xs text-gray-dark dark:text-white font-bold"}>{data.country_code}</span>
                        </div>
                    </div>
                    {data.marker && (
                        <div className={"flex flex-row gap-2"}>
                            <span className={"text-xs text-gray font-bold"}>Marker:</span>
                            <span
                                className={"text-xs text-gray-dark dark:text-white font-bold"}>{data.marker}</span>
                        </div>
                    )}
                </div>
            </div>

            <div className={"flex flex-row bg-gray/10 justify-end items-center p-2"}>
                <ActionTooltip label={translate('def.delete')}>
                    <div
                        className={"p-1 text-gray-dark dark:text-white rounded-md bg-red/30 hover:bg-red/50 cursor-pointer"}
                        onClick={onDelete && (() => onDelete(data.id.toString()))}
                    >
                        <Trash size={14}/>
                    </div>
                </ActionTooltip>
            </div>
        </div>
    );
}

export {SmsCountryItem}
