import React, {useEffect, useState} from "react";
import {Card} from "../../components/shared/card";
import {useLanguage} from "../../contexts/LanguageContext";
import {Separator} from "../../components/shared/separator";
import {Input} from "../../components/shared/Input";
import {ChevronRight, SearchIcon} from "lucide-react";
import {Table, TableBody, TableCell, TableColumnHeaderCell, TableHeader, TablePagination, TableRow} from "../../components/shared/table";
import {getDateLocal} from "../../lib/utils";
import {useLocation} from "react-router";
import {MachineDataList, transformJsonToMachineDataListCount} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useNavigate} from "react-router-dom";
import {ActionTooltip} from "../../components/shared/tooltip";

export default function MachinesScene() {

    const {translate} = useLanguage();
    const {language} = useLanguage();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchParam = searchParams.get('search') || '';

    const [loading, setLoading] = React.useState<boolean>(true);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(10);
    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: '', ask: true});
    const [search, setSearch] = useState<string>(searchParam);
    const [machines, setMachines] = useState<MachineDataList>({totalCount: 0, list: []});

    const loadMachines = () => {
        axiosPrivate.get(`/machines/list?page=${currentPage}&size=${itemsPerPage}&search=${search}&sort=${sorted.key + ':' + sorted.ask}`).then((response) => {
            if (response.status === 200) {
                setMachines(transformJsonToMachineDataListCount(response.data.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadMachines();
    }, [axiosPrivate, currentPage, itemsPerPage]);

    useEffect(() => {
        setCurrentPage(1);
        loadMachines();
    }, [search, sorted]);

    function handleOpenDetailMachine(name: string) {
        navigate(`/machines/detail?name=${name}`);
    }

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('machines.list.title-page')}</span>
            <Card>
                <div className={"flex flex-col gap-4"}>
                    <div className={"flex flex-row justify-end"}>
                        <div className={"flex w-52"}>
                            <Input type={"search"}
                                   label={"Search"}
                                   value={search}
                                   onChange={(e) => setSearch(e.target.value)}
                                   icon={<SearchIcon size={20}/>}
                            />
                        </div>
                    </div>
                    <Separator orientation={"horizontal"}/>
                    <div className={"flex flex-col gap-4"}>
                        <Table loading={loading} onSorted={(key, ask) => setSorted({key: key, ask: ask})}>
                            <TableHeader>
                                <TableColumnHeaderCell sortKey={"nameMachine"}>
                                    Name
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"account"}>
                                    Account Login
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"recoveryLogin"}>
                                    Recovery Login
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"status"}>
                                    {translate('def.status')}
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    Android
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"timeCreate"}>
                                    Created
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"timeUpdate"}>
                                    Updated
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    {translate('def.actions')}
                                </TableColumnHeaderCell>
                            </TableHeader>

                            <TableBody>
                                {machines.list.map((item, index) => (
                                    <TableRow key={index} rowIndex={index}>
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                        <TableCell>
                                            {item.account === 'NULL' ?
                                                <span>NULL</span>
                                                : <a className={"hover:text-green transition-all"}
                                                     rel={"noreferrer"}
                                                     href={`/accounts?search=${item.account}`}
                                                     target={"_blank"}>{item.account}</a>}
                                        </TableCell>
                                        <TableCell>
                                            {item.recoveryLogin}
                                        </TableCell>
                                        <TableCell>
                                            {item.status}
                                        </TableCell>
                                        <TableCell>
                                            {item.androidVersion} ({item.versionAndroid})
                                        </TableCell>
                                        <TableCell>
                                            {getDateLocal(item.dateCreate, language)}
                                        </TableCell>
                                        <TableCell>
                                            {getDateLocal(item.dateUpdate, language)}
                                        </TableCell>
                                        <TableCell>
                                            <div className={"flex w-full justify-center gap-2 p-2"}>
                                                <ActionTooltip label={translate('machines.list.open-detail')}>
                                                    <ChevronRight
                                                        className={"cursor-pointer bg-green/40 hover:bg-green/80 rounded-md p-1"}
                                                        onClick={() => handleOpenDetailMachine(item.name)}
                                                        size={20}/>
                                                </ActionTooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                        <TablePagination totalItems={machines.totalCount}
                                         currentPage={currentPage}
                                         defaultSelectRowsPerPage={10}
                                         itemsPerPage={itemsPerPage}
                                         onChangeItemsPerPage={setItemsPerPage}
                                         onChangePage={setCurrentPage}/>
                    </div>
                </div>
            </Card>
        </div>
    )
}
