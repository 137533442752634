import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import React, {useEffect, useState} from "react";
import {Input} from "../../components/shared/Input";
import {RefreshCcwDot, SearchIcon} from "lucide-react";
import {Table, TableBody, TableCell, TableColumnHeaderCell, TableHeader, TablePagination, TableRow} from "../../components/shared/table";
import {ProxyConfigData, ProxyData, ProxyDataList, transformJsonToProxyConfigDataList, transformJsonToProxyDataListCount} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {ActionTooltip} from "../../components/shared/tooltip";
import {useToast} from "../../contexts/ToastContext";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";

export const ProxyListScene: React.FC = () => {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();
    const {add} = useToast();

    const [search, setSearch] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: '', ask: false});
    const [loading, setLoading] = React.useState<boolean>(true);
    const [proxies, setProxies] = useState<ProxyDataList>({totalCount: 0, list: []});

    const [config, setConfig] = useState<string>('0');
    const [configs, setConfigs] = useState<ProxyConfigData[]>([]);

    const loadProxies = () => {
        axiosPrivate.get(`/proxies/list?page=${currentPage}&size=${itemsPerPage}&search=${search}&sort=${sorted.key + ':' + sorted.ask}&config_id=${config}`).then((response) => {
            if (response.status === 200) {
                setProxies(transformJsonToProxyDataListCount(response.data.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    const loadConfigs = () => {
        axiosPrivate.get(`/proxies/configs`).then((response) => {
            if (response.status === 200) {

                const result: ProxyConfigData[] = [];

                result.push({id: 0, service_type: 'All'});
                result.push(...transformJsonToProxyConfigDataList(response.data.data));

                setConfigs(result);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleReload = (item: ProxyData) => {
        add({message: translate('proxy.list.proxy-reload-started'), type: 'info'});

        axiosPrivate.post(`/proxies/reload/${item.id}`).then((response) => {
            add({message: translate('proxy.list.proxy-reload-success'), type: 'success'});
        }).catch((error) => {
            console.log(error);
            add({message: translate('proxy.list.proxy-reload-error'), type: 'error'});
        });
    }

    useEffect(() => {
        loadProxies();
    }, [axiosPrivate, currentPage, itemsPerPage]);

    useEffect(() => {
        setCurrentPage(1);
        loadProxies();
    }, [axiosPrivate, search, sorted, config]);

    useEffect(() => {
        loadConfigs();
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('proxy.list.title-page')}</span>

            <Card>
                <div className={"flex flex-col gap-4"}>
                    <div className={"flex lg:flex-row sm:flex-col gap-2 justify-between"}>
                        <div className={"flex w-96 items-center"}>
                            <Select
                                disabled={loading}
                                onValueChange={setConfig}
                            >

                                <SelectTrigger
                                    className="bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none">
                                    <SelectValue placeholder={translate('proxy.list.select-config')}/>
                                </SelectTrigger>

                                <SelectContent>
                                    {Object.values(configs).map((config) => (
                                        <SelectItem
                                            key={config.id}
                                            value={config.id ? config.id.toString() : '0'}
                                            className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                        >
                                            <div className={"flex flex-row gap-2 justify-end"}>
                                                <div className={"flex"}>
                                                    <span className={"text-sm font-bold"}>{config.service_type}</span>
                                                </div>
                                                {(config.locale) && (
                                                    <div className={"flex"}>
                                                        <ActionTooltip
                                                            label={"Locale: " + config.locale.country + "-" + config.locale.language}>
                                                            <img
                                                                className={"h-4 w-4"}
                                                                alt={config.locale.country.toUpperCase()}
                                                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${config.locale.country.toUpperCase()}.svg`}/>
                                                        </ActionTooltip>
                                                    </div>
                                                )}
                                            </div>
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        <div className={"flex w-52"}>
                            <Input type={"search"}
                                   label={translate('def.search')}
                                   value={search}
                                   onChange={(e) => setSearch(e.target.value)}
                                   icon={<SearchIcon size={20}/>}
                            />
                        </div>
                    </div>
                    <div className={"flex flex-col gap-4"}>
                        <Table loading={loading} onSorted={(key, ask) => setSorted({key: key, ask: ask})}>
                            <TableHeader>
                                <TableColumnHeaderCell sortKey={"puid"}>
                                    Id
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"proxy"}>
                                    {translate('proxy.list.proxy')}
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell sortKey={"machine"}>
                                    {translate('proxy.list.machine')}
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    {translate('proxy.list.config-id')}
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    {translate('proxy.list.params')}
                                </TableColumnHeaderCell>
                                <TableColumnHeaderCell>
                                    {translate('def.actions')}
                                </TableColumnHeaderCell>
                            </TableHeader>

                            <TableBody>
                                {proxies.list.map((item, index) => (
                                    <TableRow key={index} rowIndex={index}>
                                        <TableCell>
                                            {item.id}
                                        </TableCell>
                                        <TableCell>
                                            {item.proxy}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                item.machine === 'NULL' || !item.machine ?
                                                    <span>NULL</span>
                                                    : <a href={`/machines?search=${item.machine}`}
                                                         className={"hover:text-green transition-all"}
                                                         rel={"noreferrer"}
                                                         target={"_blank"}>{item.machine}</a>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {item.config_id}
                                        </TableCell>
                                        <TableCell className={"p-2"}>
                                            <div key={index + '_params'} className={"flex flex-col gap-1 justify-center"}>
                                                {item.params === null ? 'NULL' : Object.entries(item.params)
                                                    .map(([key, value]) =>
                                                        <span key={key + '_' + value} className={"text-xs font-bold text-gray-dark dark:text-white"}>
                                                            {key}: {value}
                                                        </span>
                                                    )
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className={"flex flex-row justify-center gap-2 p-2"}>
                                                <ActionTooltip label={translate('proxy.list.reload')}>
                                                    <div
                                                        className={"p-1 text-gray-dark dark:text-white rounded-md bg-orange/30 hover:bg-orange/50 cursor-pointer transition-all"}
                                                        onClick={() => handleReload(item)}
                                                    >
                                                        <RefreshCcwDot size={14}/>
                                                    </div>
                                                </ActionTooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                        <TablePagination totalItems={proxies.totalCount}
                                         currentPage={currentPage}
                                         defaultSelectRowsPerPage={10}
                                         itemsPerPage={itemsPerPage}
                                         onChangeItemsPerPage={setItemsPerPage} onChangePage={setCurrentPage}/>
                    </div>
                </div>
            </Card>
        </div>
    )
}
