import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import {TaskData} from "./task-data";
import {PassiveData} from "./passive-data";
import {MachineData} from "./machine-data";
import {InstallMonitorAdmin} from "./install-monitor-admin";

export default function DashboardAdminScene() {

    const {translate} = useLanguage();

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('dashboard.title-page')}</span>
            <div className="flex flex-row">
                <InstallMonitorAdmin/>
            </div>
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-4">
                <TaskData/>
                <PassiveData/>
            </div>
            <div className="flex flex-row">
                <MachineData/>
            </div>

            <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-4">
                <Card title={"On/Off Servers"} isExpanded={true}>
                    <h1 className="text-xl font-bold text-gray-dark dark:text-white underline">{translate('welcome')}</h1>
                </Card>

                <Card title={"Change Status"} isExpanded={true}>
                    <h1 className="text-xl font-bold text-gray-dark dark:text-white underline">{translate('welcome')}</h1>
                </Card>
                <Card title={"Crypto Manager"} isExpanded={true}>
                    <h1 className="text-xl font-bold text-gray-dark dark:text-white underline">{translate('welcome')}</h1>
                </Card>
            </div>

            <div className="flex flex-row">
                <Card title={"Success Data"}>

                </Card>
            </div>
        </div>
    );
}