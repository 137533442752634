import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {SearchIcon} from "lucide-react";
import {Input} from "../../components/shared/Input";
import {CampaignData, transformJsonToCampaignDataList} from "../../lib/transform-json";
import {CampaignItem, ItemCampaignCardLoader} from "./campaign-item";
import {TabsContainer} from "../../components/shared/tabs-container";
import {useToast} from "../../contexts/ToastContext";
import {useModal} from "../../hooks/use-modal-store";

export default function CampaignsScene() {

    const {translate} = useLanguage();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const {add} = useToast();
    const {onOpen} = useModal();

    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [campaigns, setCampaigns] = useState<CampaignData[]>([]);

    const tabs = ['ALL', 'IN_PROGRESS', 'PENDING', 'FINISHED'];

    const [tab, setTab] = useState<string>(tabs[0]);


    const onClick = (id: string) => {
        navigate(`/campaign/detail?cuid=${id}`);
    }

    const handleDeleteCampaign = (id: string) => {
        function handlerDeleteCampaign() {
            axiosPrivate.delete(`/campaigns?cuid=${id}`)
                .then((response) => {
                    if (response.status === 200) {
                        add({type: 'info', message: translate('campaigns.detail.deleted')});
                        loadCampaigns();
                    }
                }).catch((error) => {
                add({type: 'error', message: translate('campaigns.detail.deleted-error')});
            });
        }

        onOpen(
            'delete-universal',
            {
                deleteData:
                    {
                        title: translate('def.delete') + '?',
                        description: translate('campaigns.detail.delete-description'),
                        button_delete: translate('def.delete'),
                        button_close: translate('def.cancel')
                    }
            },
            {onConfirm: () => handlerDeleteCampaign()}
        );
    };

    const loadCampaigns = () => {
        setLoading(true);

        axiosPrivate.get(`/campaigns/list?search=${search}&status=${tab}`).then((response) => {
            if (response.status === 200 && response.data.data) {
                setCampaigns(transformJsonToCampaignDataList(response.data.data));
            }

            setLoading(false)
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadCampaigns();
    }, [search, tab]);

    return (
        <div className="flex flex-col gap-4">
            <div className={"flex flex-row justify-between"}>
                <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('campaigns.my.title-page')}</span>
                <div className={"flex w-52"}>
                    <Input
                        type={"text"}
                        label={translate('def.search')}
                        onChange={(e) => setSearch(e.target.value)}
                        icon={<SearchIcon size={20}/>}
                    />
                </div>
            </div>

            <Card>
                <TabsContainer tabs={tabs} onTabChange={setTab}
                               classNameDivChildren={`grid ${campaigns.length === 0 || loading ? 'grid-cols-1' : 'lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2'} gap-2 p-2`}>
                    {loading ? (
                        <div className={"grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-2 p-2"}>
                            <ItemCampaignCardLoader/>
                            <ItemCampaignCardLoader/>
                            <ItemCampaignCardLoader/>
                        </div>
                    ) : campaigns.length === 0 ?
                        <span
                            className={"w-full text-center font-bold text-gray-dark dark:text-white p-2"}>{translate('campaigns.my.no-found-campaigns')}</span> :
                        campaigns.map((campaign, index) => (
                                <CampaignItem key={index} campaignData={campaign} onClick={onClick} onDelete={handleDeleteCampaign}/>
                            )
                        )}
                </TabsContainer>
            </Card>
        </div>
    )
}
