import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {cn} from "../../lib/utils";
import {Button} from "./button";
import {ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Pointer, SortAsc} from "lucide-react";
import {ActionTooltip} from "./tooltip";
import {Checkbox} from "./checkbox";
import {useLanguage} from "../../contexts/LanguageContext";

type TableProps = {
    children: ReactElement<TableHeaderProps | TableBodyProps>[] | ReactElement<TableHeaderProps | TableBodyProps>;
    loading?: boolean;
    selectable?: boolean;
    onSelect?: (indexes: number[]) => void;
    onSorted?: (key: string, ask: boolean) => void
}

const Table: React.FC<TableProps> = ({children, ...props}) => {

    const header = React.Children.toArray(children).find((child) => React.isValidElement(child) && child.type === TableHeader) as React.ReactElement;

    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: '', ask: true});

    const onSorted = props.onSorted;

    const handleSorted = (key: string) => {
        setSorted(prevState => {
            const newState = {key: key, ask: !prevState.ask};
            props.onSorted && props.onSorted(newState.key, newState.ask);
            return newState;
        });
    }

    const clonedHeaderChildren = React.Children.map(header?.props.children, (child) => {
        if (React.isValidElement(child) && child.type === TableColumnHeaderCell) {
            const headerCell = child as React.ReactElement<TableColumnHeaderCellProps>;
            return React.cloneElement(headerCell, {...headerCell.props, sorted, onSorted, handleSorted});
        }
        return child;
    });

    const body = React.Children.toArray(children).find((child) => React.isValidElement(child) && child.type === TableBody) as React.ReactElement;

    const countData = React.Children.count(body.props.children);

    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const selectable = props.selectable ? props.selectable : false;

    const totalRows = React.Children.count(body.props.children);

    useEffect(() => {
        props.onSelect && props.onSelect(selectedRows);
    }, [selectedRows]);

    const handleAllSelectRow = (all: boolean) => {
        setSelectedRows(prevState => {
            const indices = [];
            if (all) {
                for (let i = 0; i < totalRows; i++) {
                    indices.push(i);
                }
            }
            return indices;
        });
    };

    return (/*props.loading ? <LoadingTable/> :*/
            // countData === 0 ?
            //     <div className={"flex flex-row w-full items-center justify-center  border rounded-md border-gray-dark-border dark:border-gray-border"}>
            //         <span className={"text-gray-dark dark:text-white m-2"}>No data</span>
            //     </div>
            //     :
                <div className="flex overflow-x-auto">
                    <table
                        className="w-full rounded-md border border-gray-border dark:border-gray-dark-border overflow-hidden table-auto">
                        <thead
                            className={`w-full border-b border-gray-border dark:border-gray-dark-border bg-green/50 dark:bg-green-dark/50`}>
                        <tr>
                            {selectable && <TableColumnSelect onSelect={handleAllSelectRow}
                                                              checked={selectedRows.length === totalRows}/>}
                            {clonedHeaderChildren}
                        </tr>
                        </thead>
                        {React.cloneElement(body, {selectable, selectedRows, setSelectedRows})}
                    </table>
                </div>
    );
}

interface TableHeaderProps {
    children: ReactElement<TableColumnHeaderCellProps>[] | ReactElement<TableColumnHeaderCellProps>;
}

const TableHeader: React.FC<TableHeaderProps> = ({children}) => {
    return <thead>{children}</thead>;
}

interface TableColumnHeaderCellProps {
    children?: ReactNode;
    sortKey?: string;
    onSorted?: (key: string, ask: boolean) => void;
    sorted?: { key: string, ask: boolean };
    handleSorted?: (key: string) => void;
    className?: string;
}

const TableColumnHeaderCell: React.FC<TableColumnHeaderCellProps> = ({children, ...props}) => {
    const isSortable = !!props.onSorted && !!props.sortKey && !!props.handleSorted;

    const isActive = props.sorted && props.sorted.key === props.sortKey;

    const sortIcon = isActive ? (
        <SortAsc size={15} className={`text-orange ${props.sorted && props.sorted.ask ? '' : 'transform rotate-180'}`}/>
    ) : (
        <Pointer size={15}/>
    );

    return (
        <th
            onClick={() => props.sortKey && props.handleSorted && props.handleSorted(props.sortKey)}
            className={cn(`h-12 border-r whitespace-nowrap border-gray-border dark:border-gray-dark-border p-2 text-xs font-bold text-gray-dark dark:text-white tracking-wider ${isSortable ? 'cursor-pointer' : ''}`, props.className)}>
            <div className="flex flex-row gap-2 items-center justify-center whitespace-nowrap">
                {children}
                {isSortable && sortIcon}
            </div>
        </th>
    );
}

interface TableColumnSelectProps {
    onSelect: (all: boolean) => void;
    checked: boolean;
}

const TableColumnSelect: React.FC<TableColumnSelectProps> = ({...props}) => {
    return (
        <th className={"w-12"}>
            <div className={"flex flex-row items-center justify-center"}>
                <ActionTooltip label={"Select All"}>
                    <Checkbox onCheck={(checked) => props.onSelect && props.onSelect(checked)}
                              defaultChecked={props.checked} className={"h-[18px] w-[18px]"}/>
                </ActionTooltip>
            </div>
        </th>
    );
}

interface TableBodyProps {
    children: ReactElement<TableRowProps>[] | ReactElement<TableRowProps>;
    selectable?: boolean;
    selectedRows?: number[];
    setSelectedRows?: React.Dispatch<React.SetStateAction<number[]>>;
}

const TableBody: React.FC<TableBodyProps> = ({children, ...props}) => {

    const handleSelectRow = (index: number) => {
        props.setSelectedRows && props.setSelectedRows(prevState => {
            const newState = [...prevState];
            if (prevState.some(select => select === index)) {
                newState.splice(newState.indexOf(index), 1);
            } else {
                newState.push(index);
            }
            return newState;
        })
    }

    const renderRows = React.Children.map(children, (child, index) => {
        if (props.selectable) {

            const rowProps = child.props;

            return (
                <TableRow key={rowProps.rowIndex} rowIndex={rowProps.rowIndex}>
                    <TableCell>
                        <div className={"flex flex-row items-center justify-center"}>
                            <Checkbox className={"h-[18px] w-[18px]"}
                                      onCheck={() => handleSelectRow(rowProps.rowIndex || 0)}
                                      defaultChecked={props.selectedRows ? props.selectedRows.some(row => row === index) : false}/>
                        </div>
                    </TableCell>
                    {child.props.children}
                </TableRow>
            );
        }
        return child;
    });

    return <tbody>{renderRows}</tbody>;
}

interface TablePaginationProps {
    totalItems: number,
    currentPage: number,
    rowsPerPageOptions?: number[],
    itemsPerPage?: number,
    defaultSelectRowsPerPage?: number,
    onChangePage?: (pageNumber: number) => void,
    onChangeItemsPerPage?: (itemsPerPage: number) => void
}

const TablePagination: React.FC<TablePaginationProps> = ({
                                                             totalItems = 0,
                                                             currentPage = 1,
                                                             rowsPerPageOptions = [5, 10, 25, -1],
                                                             onChangePage,
                                                             itemsPerPage = 5,
                                                             defaultSelectRowsPerPage = 5,
                                                             onChangeItemsPerPage
                                                         }) => {

    const {translate} = useLanguage();

    const [hideButtons, setHideButtons] = useState<boolean>(itemsPerPage === -1);

    useEffect(() => {
        setHideButtons(itemsPerPage > -1)
    }, [itemsPerPage]);

    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const isPreviousDisabled = currentPage === 1;
    const isNextDisabled = currentPage === pageCount || pageCount === 0;

    return (
        <div className="flex justify-center w-full">
            <div className="flex flex-row gap-6">
                <div className={"flex flex-row gap-2 items-center"}>
                    <span className={"lg:visible text-xs text-gray-dark dark:text-white"}>{translate('table.row_per_page')}:</span>
                    <select
                        className="h-6 text-xs rounded-md cursor-pointer block w-10 pl-2 bg-gray/70 text-white"
                        defaultValue={defaultSelectRowsPerPage}
                        onChange={
                            (event) => {
                                onChangeItemsPerPage && onChangeItemsPerPage(parseInt(event.target.value) > 0 ? parseInt(event.target.value) : totalItems > 0 ? totalItems : 1);
                                onChangePage && onChangePage(1)
                            }
                        }>
                        {rowsPerPageOptions && rowsPerPageOptions.map((option, index) => (
                            <option key={index}
                                    value={option}>{option === -1 ? 'All' : option}</option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-row gap-2 text-gray-dark dark:text-white text-xs items-center">
                    <span
                        className={"whitespace-nowrap"}>
                        {`${itemsPerPage === -1 ? '1' : itemsPerPage * (currentPage - 1) + 1}-${Math.min(itemsPerPage * currentPage, totalItems)} ${translate('table.of')} ${totalItems}`}
                    </span>
                </div>
                {hideButtons && (
                    <div className={`flex flex-row gap-2 text-gray-dark dark:text-white text-xs items-center`}>
                        <Button className={`flex h-6 w-6 p-1 items-center justify-items-center ${isPreviousDisabled ? 'cursor-no-drop' : ''}`}
                                onClick={() => !isPreviousDisabled && onChangePage && onChangePage(1)}
                                disabled={isPreviousDisabled}
                        >
                            <ChevronsLeft/>
                        </Button>
                        <Button className={`flex h-6 w-6 p-1 items-center justify-items-center ${isPreviousDisabled ? 'cursor-no-drop' : ''}`}
                                onClick={() => !isPreviousDisabled && onChangePage && onChangePage(currentPage - 1)}
                                disabled={isPreviousDisabled}
                        >
                            <ChevronLeft/>
                        </Button>

                        <Button className={`flex h-6 w-6 p-1 items-center justify-items-center ${isNextDisabled ? 'cursor-no-drop' : ''}`}
                                onClick={() => !isNextDisabled && onChangePage && onChangePage(currentPage + 1)}
                                disabled={isNextDisabled}
                        >
                            <ChevronRight/>
                        </Button>
                        <Button className={`flex h-6 w-6 p-1 items-center justify-items-center ${isNextDisabled ? 'cursor-no-drop' : ''}`}
                                onClick={() => !isNextDisabled && onChangePage && onChangePage(pageCount)}
                                disabled={isNextDisabled}
                        >
                            <ChevronsRight/>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

interface TableRowProps {
    children: ReactNode;
    className?: string;
    rowIndex: number;
}

const TableRow: React.FC<TableRowProps> = ({children, ...props}) => {

    const hasHeaderCell = React.Children.toArray(children).some(
        (child) => React.isValidElement(child) && child.type === TableColumnHeaderCell
    );

    const classN = hasHeaderCell
        ? 'min-h-16'
        : `${(props.rowIndex || 0) % 2 === 0 ? 'bg-green/10 dark:bg-green-dark/10' : 'bg-green/20 dark:bg-green-dark/20'} h-10 hover:bg-green/40 text-xs text-dark-gray dark:text-white dark:hover:bg-green-dark/40 transition-all`;

    return <tr className={cn(classN, props.className)}>{children}</tr>;
}

interface TableCellProps {
    children: ReactNode;
    className?: string;
}

const TableCell: React.FC<TableCellProps> = ({children, className}) => {
    return <th className={cn("px-1", className)}>{children}</th>;
}

const LoadingTable: React.FC = () => {
    return (
        <div role="status"
             className="w-full p-4 space-y-4 border border-gray/20 divide-y divide-gray/20 rounded-md animate-pulse dark:divide-gray/70 md:p-6 dark:border-gray/70">
            <div className="flex items-center justify-between">
                <div>
                    <div className="h-2.5 bg-gray/30 rounded-full dark:bg-gray/60 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray/20 rounded-full dark:bg-gray/70"></div>
                </div>
                <div className="h-2.5 bg-gray/30 rounded-full dark:bg-gray/70 w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-gray/30 rounded-full dark:bg-gray/60 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray/70"></div>
                </div>
                <div className="h-2.5 bg-gray/30 rounded-full dark:bg-gray/70 w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-gray/30 rounded-full dark:bg-gray/60 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray/20 rounded-full dark:bg-gray/70"></div>
                </div>
                <div className="h-2.5 bg-gray/30 rounded-full dark:bg-gray/70 w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-gray/30 rounded-full dark:bg-gray/60 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray/20 rounded-full dark:bg-gray/70"></div>
                </div>
                <div className="h-2.5 bg-gray/30 rounded-full dark:bg-gray/70 w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-gray/30 rounded-full dark:bg-gray/60 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray/20 rounded-full dark:bg-gray/70"></div>
                </div>
                <div className="h-2.5 bg-gray/30 rounded-full dark:bg-gray/70 w-12"></div>
            </div>
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export {Table, TableHeader, TableColumnHeaderCell, TableBody, TableRow, TableCell, TablePagination}
