import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLanguage} from "../../contexts/LanguageContext";
import {useToast} from "../../contexts/ToastContext";
import {Card} from "../../components/shared/card";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {Input} from "../../components/shared/Input";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";
import {
    AppData,
    CampaignData,
    KeywordData,
    LocaleData,
    transformJsonToAppDataList,
    transformJsonToKeywordDataList,
    transformJsonToLocaleDataList
} from "../../lib/transform-json";
import {Separator} from "../../components/shared/separator";
import {Button} from "../../components/shared/button";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {ActionTooltip} from "../../components/shared/tooltip";
import {Edit, Trash} from "lucide-react";
import {TasksTabs} from "./tasks-tabs";
import {RateStars} from "../../components/shared/rate-stars";
import {useLocation} from "react-router";
import {SelectKeywords} from "./select-keywords";
import {useModal} from "../../hooks/use-modal-store";

export default function CampaignAddScene() {

    const {translate} = useLanguage();
    const navigate = useNavigate();
    const {add} = useToast();
    const {isOpen, onOpen, data} = useModal();

    const axiosPrivate = useAxiosPrivate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const auidParam = searchParams.get('auid') || '';

    const [responseApps, setResponseApps] = useState<AppData[]>([]);
    const [selectedApp, setSelectedApp] = useState<AppData | null>(null);
    const [locales, setLocales] = useState<LocaleData[]>([]);
    const [selectedLocale, setSelectedLocale] = useState<LocaleData | null>(null);

    const [searchApp, setSearchApp] = useState<AppData[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<Record<string, { value: string }>>({});

    const [form, setForm] = useState<CampaignData>({
        app: {
            auid: '',
            app_id: '',
            details: {},
            status: '',
            rating: {
                rating: 0,
                reviews: '0',
                installs: '0',
            }
        },
        locale: {
            id: 0,
            country: '',
            name: '',
            language: '',
        },
        days: [
            {
                day: 1,
                tasks: [
                    {
                        count: 10,
                        type: 'LINK',
                        other_params: {
                            keyword: '',
                            stars: 0,
                        },
                    }
                ]
            }
        ],
    });

    const [taskTypes, setTaskTypes] = useState<string[]>([]);

    const [days, setDays] = useState<number[]>([1]);
    const [selectedDay, setSelectedDay] = useState<number>(1);

    const handleOpenKeys = () => {
        window.open(`/keywords${form.app.auid.length > 0 ? '?auid=' + form.app.auid : ''}`, "_blank");
    };

    const handleAddDay = (number: number) => {

        if (number > 14) {
            add({type: 'error', message: 'Max days is 14'});
            return;
        }
        if (number <= 0) {
            add({type: 'error', message: 'Min days is 1'});
            return;
        }

        setDays(prevDays => {
            if (prevDays.length < number) {
                return Array.from({length: number}, (_, index) => index + 1);
            } else {
                return prevDays.slice(0, number);
            }
        });
    }

    const [responseKeys, setResponseKeys] = useState<KeywordData[]>([]);

    const loadKeys = () => {
        if (selectedApp === null || selectedLocale === null) {
            return;
        }

        setLoading(true);

        axiosPrivate.get(`/keywords/list?auid=${selectedApp?.auid}&locale_id=${selectedLocale.id}`).then((response) => {
            if (response.status === 200 && response.data.data) {
                setResponseKeys(transformJsonToKeywordDataList(response.data.data.list));
            }

            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadKeys();
    }, [selectedApp, selectedLocale]);

    const openAddKeywordsModal = () => {
        onOpen(
            'add-keywords',
            {
                appData: selectedApp!!,
                localeData: selectedLocale!!
            },
            {
                onConfirm: (keywords) => {
                    if (keywords) {
                        const keywordsData: KeywordData[] = keywords?.map(k => ({
                            id: '',
                            keyword: k,
                            locale: selectedLocale!!,
                            favorite: false,
                            status: '',
                            position: 0,
                            position_diff: 0
                        }));
                        setResponseKeys(prev => {
                            const newKeys = keywordsData.filter(kwd => !prev.some(pk => pk.keyword === kwd.keyword));
                            return [...prev, ...newKeys];
                        });
                        keywords.forEach(k => handleAddTask('SEARCH', k));
                        loadKeys();
                    }
                }
            }
        );
    }

    const openAddAppModal = () => {
        onOpen(
            'add-app',
            {},
            {
                onAddedApp: (appData) => {
                    setResponseApps(prev => {
                        if (!prev.some(app => app.auid === appData.auid)) {
                            return [...prev, appData];
                        }
                        return prev;
                    });

                    setSelectedApp(appData);
                }
            }
        );
    }

    useEffect(() => {
        if (days.length === 0) setDays([1]);
        setSelectedDay(1);

        setForm(prev => {
            const diff = days.length - prev.days.length;
            if (diff > 0) {
                const firstDayTasks = prev.days.length > 0 ? prev.days[0].tasks : [];
                const newDays = Array.from({length: diff}, (_, index) => ({
                    day: prev.days.length + index + 1,
                    tasks: firstDayTasks.map(task => ({...task})),
                }));
                return {
                    ...prev,
                    days: [...prev.days, ...newDays],
                };
            } else if (diff < 0 && days.length > 0) {
                return {
                    ...prev,
                    days: prev.days.slice(0, days.length),
                };
            }
            return prev;
        });
    }, [days]);

    const handleChangeType = (taskIndex: number, value: string) => {
        setForm((prev) => ({
            ...prev,
            days: prev.days.map((day) => ({
                ...day,
                tasks: day.tasks.map((task, tIndex) => {
                    if (tIndex === taskIndex) {
                        return {
                            ...task,
                            type: value,
                        };
                    }
                    return task;
                }),
            })),
        }));
    };

    const handleChangeCount = (taskIndex: number, value: number) => {
        setForm((prev) => {
            const updatedDays = [...prev.days];
            const dayTasks = [...updatedDays[selectedDay - 1].tasks];

            dayTasks[taskIndex] = {
                ...dayTasks[taskIndex],
                count: value,
            };

            updatedDays[selectedDay - 1].tasks = dayTasks;
            return {
                ...prev,
                days: updatedDays,
            };
        });
    }

    const updateTaskField = (index: number, fieldName: string, value: string | number, allTasks: boolean = false) => {
        setForm((prev) => {
            const updatedDays = [...prev.days];

            if (allTasks) {
                updatedDays.forEach((day) => {
                    const dayTasks = [...day.tasks];
                    dayTasks[index] = {
                        ...dayTasks[index],
                        other_params: {
                            ...dayTasks[index].other_params,
                            [fieldName]: value,
                        },
                    };
                    day.tasks = dayTasks;
                });
            } else {

                const dayTasks = [...updatedDays[selectedDay - 1].tasks];

                dayTasks[index] = {
                    ...dayTasks[index],
                    other_params: {
                        ...dayTasks[index].other_params,
                        [fieldName]: value,
                    },
                };

                updatedDays[selectedDay - 1].tasks = dayTasks;
            }

            return {
                ...prev,
                days: updatedDays,
            };
        });
    };

    const handleAddTask = (type?: string, keyword?: string) => {
        setForm((prevForm) => {
            const updatedForm = {...prevForm};
            updatedForm.days = updatedForm.days.map((day) => ({
                ...day,
                tasks: [
                    ...day.tasks,
                    {
                        count: 10,
                        type: type ? type : 'LINK',
                        other_params: {
                            keyword: keyword ? keyword : '',
                            stars: 0,
                        },
                    }
                ]
            }));
            return updatedForm;
        });
    };

    const handleRemoveTask = (index: number) => {
        setForm((prevForm) => ({
            ...prevForm,
            days: prevForm.days.map((day) => {
                const updatedTasks = [...day.tasks];
                updatedTasks.splice(index, 1);
                return {
                    ...day,
                    tasks: updatedTasks
                };
            }),
        }));
    };

    const loadApps = () => {
        axiosPrivate.get('/apps/list').then((response) => {
            if (response.status === 200) {
                let appData = transformJsonToAppDataList(response.data.data).filter(app => app.status === 'ACTIVE');
                setResponseApps(appData);
                setSearchApp(appData);
                if (auidParam !== '') {
                    let find = responseApps.find(app => app.auid === auidParam);
                    if (find) {
                        setForm(prev => ({...prev, app: find!!}));
                    }
                }
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    const loadData = () => {
        setLoading(true);

        axiosPrivate.get('/locales/list').then((response) => {
            if (response.status === 200) {
                let localeData = transformJsonToLocaleDataList(response.data.data)
                    .filter(locale => locale.type === 'STANDARD')
                    .sort((a, _) => a.country === 'us' ? -1 : 1);
                setLocales(localeData);
                setSelectedLocale(localeData[0]);
                loadApps();
            } else {
                add({type: 'error', message: 'Error load task types'});
            }
        }).catch((error) => {
            console.log(error);
            add({type: 'error', message: 'Error load task types'});
        });

        axiosPrivate.get('/campaigns/task/types').then((response) => {
            if (response.status === 200) {
                setTaskTypes(response.data.data);
            } else {
                add({type: 'error', message: 'Error load task types'});
            }
        }).catch((error) => {
            console.log(error);
            add({type: 'error', message: 'Error load task types'});
        });
    }

    useEffect(() => {
        setForm(prev => ({
            ...prev,
            locale: {
                id: selectedLocale?.id || 0,
                language: selectedLocale?.language || '',
                country: selectedLocale?.country || '',
                name: selectedLocale?.name || '',
            }
        }));
    }, [selectedLocale]);

    useEffect(() => {
        if (selectedApp)
            setForm(prev => ({...prev, app: selectedApp}));
    }, [selectedApp]);

    useEffect(() => {
        loadData();
    }, []);

    const checkModel = () => {
        setError({});

        let hasError = false;

        if (form.app.auid === '') {
            setError(prev => ({...prev, app_id: {value: 'campaigns.add.app_is_require'}}));
            add({type: 'error', message: translate('campaigns.add.app_is_require')});
            hasError = true;
        }

        if (form.days.length === 0) {
            add({type: 'error', message: translate('campaigns.add.days_is_require')});
            hasError = true;
        }

        for (let i = 0; i < form.days.length; i++) {
            const day = form.days[i];
            if (day.tasks.length === 0) {
                add({
                    type: 'error',
                    message: translate('campaigns.add.tasks_is_require') + ' ' + translate('campaigns.add.day') + '-' + day.day
                });
                hasError = true;
            }
            for (let j = 0; j < day.tasks.length; j++) {
                const task = day.tasks[j];
                if (task.count <= 0) {
                    add({
                        type: 'error',
                        message: translate('campaigns.add.task_count_is_require') + ' ' + translate('campaigns.add.day') + '-' + day.day + ' ' + translate('campaigns.add.task') + '-' + (j + 1)
                    });
                    hasError = true;
                }
                if (task.type === 'SEARCH' || task.type === 'OPEN_SEARCH') {
                    if (task.other_params.keyword === '') {
                        add({
                            type: 'error',
                            message: translate('campaigns.add.keyword_is_require') + ' ' + translate('campaigns.add.day') + '-' + day.day + ' ' + translate('campaigns.add.task') + '-' + (j + 1)
                        });
                        hasError = true;
                    }
                }
                if (task.type === 'FEEDBACK_MARK' || task.type === 'FEEDBACK') {
                    if (task.other_params.stars === 0) {
                        add({
                            type: 'error',
                            message: translate('campaigns.add.stars_is_require') + ' ' + day.day + ' ' + translate('campaigns.add.day') + ' ' + translate('campaigns.add.task') + ' ' + (j + 1)
                        });
                        hasError = true;
                    }
                }
            }
        }

        if (hasError) {
            setLoading(false);
            return false;
        }

        return true;
    }

    const handlerSaveCampaign = (start: boolean) => {
        setLoading(true);

        let check = checkModel();

        if (!check) {
            return;
        }

        axiosPrivate.post(`/campaigns/save?start=${start}`, form)
            .then((response) => {
                if (response.status === 200) {
                    add({
                        type: 'success',
                        message: translate(start ? 'campaigns.add.start_campaign_success' : 'campaigns.add.save_campaign_success')
                    });
                    setLoading(false);
                    //Redirect to campaigns
                    navigate("/campaign/list");
                }
            }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('campaigns.add.title-page')}</span>

            <Card>
                <div className={"flex flex-row gap-4"}>
                    <span className={"flex text-gray-dark dark:text-white"}>{translate('campaigns.add.application')}<p
                        className={"text-red"}>*</p>:</span>

                    <div className={"flex flex-col gap-1"}>
                        <div className={"flex flex-row items-center gap-4"}>
                            <div className={"w-96 "}>
                                <Select
                                    value={auidParam === '' ? form.app.auid : auidParam}
                                    disabled={loading}
                                    onValueChange={(value) => setSelectedApp(responseApps.find(app => app.auid === value)!!)}
                                >
                                    <SelectTrigger
                                        className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-fit`}>
                                        <SelectValue placeholder="Select Application"/>
                                    </SelectTrigger>

                                    <SelectContent className={"flex flex-col"}>
                                        <div className={"h-52 overflow-scroll"}>
                                            {Object.values(searchApp).map((app) => (
                                                <SelectItem
                                                    key={app.auid}
                                                    value={app.auid}
                                                    className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                                >
                                                    <div className={"flex flex-row gap-2"}>
                                                        <Avatar className={`h-8 w-8 transition-all`}>
                                                            <AvatarFallback delayMs={500}>
                                                                I
                                                            </AvatarFallback>
                                                            <AvatarImage
                                                                src={app.details[selectedLocale?.country + '_' + selectedLocale?.language] ?
                                                                    app.details[selectedLocale?.country + '_' + selectedLocale?.language].image_src :
                                                                    app.details['us_en'].image_src}
                                                            />
                                                        </Avatar>
                                                        <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>
                                                                {app.details[selectedLocale?.country + '_' + selectedLocale?.language] ?
                                                                    app.details[selectedLocale?.country + '_' + selectedLocale?.language].name :
                                                                    app.details['us_en'].name}
                                                            </span>
                                                            <span
                                                                className={"text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                {app.details[selectedLocale?.country + '_' + selectedLocale?.language] ?
                                                                    app.details[selectedLocale?.country + '_' + selectedLocale?.language].publisher :
                                                                    app.details['us_en'].publisher}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </div>
                                    </SelectContent>
                                </Select>
                                <span className={"text-xs text-gray-dark dark:text-white"}>
                                    Choose one of your apps or
                                    <span
                                        className={"text-xs cursor-pointer text-green/50 hover:text-green hover:font-bold transition-all"}
                                        onClick={() => openAddAppModal()}> add new here</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={"flex flex-col gap-1"}>
                        <div className={"flex flex-row items-center gap-4"}>
                            <div className={"w-52"}>
                                <Select
                                    disabled={loading}
                                    value={selectedLocale?.country + '_' + selectedLocale?.language}
                                    onValueChange={(value) => setSelectedLocale(locales.find(locale => locale.country + '_' + locale.language === value)!!)}
                                >
                                    <SelectTrigger
                                        className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-fit`}>
                                        <SelectValue placeholder="Select Application"/>
                                    </SelectTrigger>

                                    <SelectContent className={"flex flex-col"}>
                                        <div className={"h-fit overflow-scroll"}>
                                            {Object.values(locales).map((locale) => (
                                                <SelectItem
                                                    key={locale.name}
                                                    value={locale.country + '_' + locale.language}
                                                    className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                                >
                                                    <div className={"flex flex-row gap-2"}>
                                                        <img
                                                            className={"h-4 w-6 rounded-md overflow-hidden"}
                                                            alt={locale.country.toUpperCase()}
                                                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${locale.country.toUpperCase()}.svg`}/>
                                                        <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>{locale.name}</span>
                                                        </div>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </div>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator orientation={"horizontal"} className={"w-full"}/>

                <div className={"flex flex-row gap-4"}>
                      <span className={"flex text-gray-dark dark:text-white"}>{translate('campaigns.add.days')}<p
                          className={"text-red"}>*</p>:</span>
                    <div className={"flex flex-col w-56 gap-1"}>
                        <Input
                            type={"number"}
                            label={translate('campaigns.add.days')}
                            value={days.length}
                            disable={loading}
                            onChange={(e) => handleAddDay(parseInt(e.target.value) || 1)}
                        />
                        <span className={"text-xs text-gray-dark dark:text-white"}>
                            {translate('campaigns.add.campaign-days-description')}
                        </span>
                    </div>
                </div>


                <Separator orientation={"horizontal"} className={"w-full"}/>

                <div className={"flex flex-row gap-4"}>
                    <span className={"flex text-gray-dark dark:text-white"}>{translate('campaigns.add.keywords')}:</span>
                    <div className={"flex flex-col gap-1"}>
                        <Button
                            className={"w-36"}
                            onClick={handleOpenKeys}
                            disabled={loading}
                        >
                            <div className={"flex flex-row gap-2 w-full items-center justify-start"}>
                                <Edit size={18}/>
                                <span className={"text-xs"}> {translate('campaigns.add.edit-keywords')}</span>
                            </div>
                        </Button>
                        <span className={"text-xs text-gray-dark dark:text-white"}>
                            {translate('campaigns.add.keywords-description')}
                        </span>
                    </div>
                </div>

                <Separator orientation={"horizontal"} className={"w-full"}/>

                <div className={"flex flex-row gap-4"}>
                    <span className={"flex text-gray-dark dark:text-white"}>{translate('campaigns.add.tasks')}
                        <p className={"text-red"}>*</p>:</span>
                    <TasksTabs
                        days={days}
                        onAddDay={() => handleAddDay(days.length + 1)}
                        onRemove={() => handleAddDay(days.length - 1)}
                        onDayClick={(day) => setSelectedDay(day)} selectedDay={selectedDay}>

                        {form.days[selectedDay - 1].tasks.map((task, index) => (
                                <div key={index} className={"flex flex-row gap-4"}>
                                    <div className={"flex flex-row gap-2 items-center"}>
                                        <span className={"text-xs text-gray-dark dark:text-white font-bold"}>
                                            {translate('campaigns.add.installs-count')}:
                                        </span>
                                        <div className={"w-16"}>
                                            <Input type={"number"} value={task.count} disable={loading}
                                                   onChange={(e) => handleChangeCount(index, parseInt(e.target.value) || 0)}/>
                                        </div>
                                    </div>

                                    <div className={"flex flex-row gap-2 items-center"}>
                                        <span
                                            className={"text-xs text-gray-dark dark:text-white font-bold"}>{translate('campaigns.add.search-by-type')}:
                                        </span>
                                        <div key={`type-${index}`} className={"w-144 flex flex-row gap-2 items-center"}>
                                            <Select
                                                disabled={loading || selectedDay !== 1}
                                                value={task.type}
                                                defaultValue={task.type}
                                                onValueChange={(value) => handleChangeType(index, value)}
                                            >
                                                <SelectTrigger
                                                    className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-fit`}>
                                                    <SelectValue placeholder="Select Type"/>
                                                </SelectTrigger>

                                                <SelectContent className={"flex flex-col"}>
                                                    <div className={"h-fit scroll-auto"}>
                                                        {Object.values(taskTypes).map((type) => (
                                                            <SelectItem
                                                                key={type}
                                                                value={type}
                                                                className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs"
                                                            >
                                                                <div className={"flex flex-row gap-1"}>
                                                                    <div
                                                                        className={"flex flex-col items-start justify-center"}>
                                                                        <span
                                                                            className={"max-w-[200px] whitespace-nowrap truncate"}>{type}</span>
                                                                    </div>
                                                                </div>
                                                            </SelectItem>
                                                        ))}
                                                    </div>
                                                </SelectContent>
                                            </Select>
                                            {(task.type === 'SEARCH' || task.type === 'OPEN_SEARCH') && (
                                                <div className={"w-52"}>
                                                    <SelectKeywords
                                                        disable={loading || selectedDay !== 1 || !selectedApp || !selectedLocale}
                                                        onSelectKeyword={(value) => updateTaskField(index, 'keyword', value)}
                                                        selectedKeyword={task.other_params.keyword}
                                                        onPutKeywords={() => openAddKeywordsModal()}
                                                        keywords={responseKeys}/>
                                                </div>
                                            )}
                                            {(task.type === 'FEEDBACK_MARK' || task.type === 'FEEDBACK') && (
                                                <RateStars
                                                    key={`stars-${index}`}
                                                    onChange={(value) => updateTaskField(index, 'stars', value)}
                                                    value={task.other_params.stars as number | 0}
                                                    countStars={5}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {form.days[selectedDay - 1].tasks.length > 1 && selectedDay === 1 && (
                                        <ActionTooltip label={translate('campaigns.add.remove-task')}>
                                            <div
                                                onClick={() => handleRemoveTask(index)}
                                                className={"flex flex-row gap-2 items-center p-2 rounded-md hover:bg-red/30 cursor-pointer"}>
                                                <Trash className={"text-gray-dark dark:text-white"} size={16}/>
                                            </div>
                                        </ActionTooltip>
                                    )}
                                </div>
                            )
                        )}

                        {selectedDay === 1 && (
                            <div className={"flex flex-row"}>
                                <Button
                                    onClick={handleAddTask}
                                    disabled={loading}
                                >
                                    {translate('campaigns.add.add-task')}
                                </Button>
                            </div>
                        )}
                    </TasksTabs>
                </div>

                <Separator orientation={"horizontal"} className={"w-full"}/>

                <div className={"flex flex-row justify-end gap-2"}>
                    <Button
                        className={"bg-blue/50 hover:bg-blue/80"}
                        onClick={() => handlerSaveCampaign(true)}
                        disabled={loading}
                    >
                        {translate('campaigns.add.start-campaign')}
                    </Button>
                    <Button
                        onClick={() => handlerSaveCampaign(false)}
                        disabled={loading}
                    >
                        {translate('def.save')}
                    </Button>
                </div>
            </Card>
        </div>
    )
}
