import React, {useEffect, useState} from "react";
import {RefreshCwIcon, ServerIcon} from "lucide-react";
import {Card} from "../../components/shared/card";
import {ActionTooltip} from "../../components/shared/tooltip";
import {Separator} from "../../components/shared/separator";
import {mockDataMachines} from "../../config/mockData";
import {getDateLocal} from "../../lib/utils";
import {useModal} from "../../hooks/use-modal-store";
import {useLanguage} from "../../contexts/LanguageContext";

const MachineData: React.FC = () => {

    const {language} = useLanguage();

    const [hover, setHover] = useState<string | undefined>(undefined);

    const loadingDataInit = {
        init: true,
        refresh: false,
    }

    const [loading, setLoading] = useState(loadingDataInit);
    const [response, setResponse] = useState<any>([]);

    const handleRefresh = () => {
        setLoading({
            ...loading,
            refresh: true
        });

        //TODO request refresh data if error show toast

        setTimeout(() => {
            setLoading({
                ...loading,
                refresh: false
            });
        }, 2500);
    }

    useEffect(() => {
        //TODO request data from server

        //Test
        setResponse(mockDataMachines);

        setTimeout(() => {
            setLoading({
                ...loading,
                init: false
            });
        }, 1500);
    }, []);

    return (
        <Card>
            <div className={"flex flex-row w-full items-center justify-between"}>
                <span className={"font-bold text-white"}>Machine Data</span>

                <div className={"flex flex-row gap-1 items-center"}>
                    {loading.init || loading.refresh ?
                        <div className={"w-20 h-2 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                        <ActionTooltip label={"Last update time"}>
                            <span
                                className={"text-xs text-gray/50"}>{getDateLocal(response.date_update, language)}</span>
                        </ActionTooltip>
                    }
                    <ActionTooltip label={"Refresh"}>
                        <div
                            className={"p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white relative flex items-center transition-all"}
                            onClick={handleRefresh}
                        >
                            <RefreshCwIcon
                                size={20}
                                className={`text-gray-dark dark:text-white cursor-pointer ${loading.init || loading.refresh ? 'animate-[spin_1.5s_infinite]' : ''}`}/>
                        </div>
                    </ActionTooltip>
                </div>
            </div>

            <Separator orientation={"horizontal"}/>

            <div className={"flex flex-col gap-2"}>
                <div className={"grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1"}>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"pl-0.5 text-green/80 font-bold"}>Active Machines</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <ActiveMachines dataServers={response.data} hover={hover} setHover={setHover}/>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"pl-0.5 text-green/80 font-bold"}>Max Start Machines</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <MaxStartMachines dataServers={response.data} hover={hover} setHover={setHover}/>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"pl-0.5 text-green/80 font-bold"}>Count All Machines</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <CountAllMachine dataServers={response.data} hover={hover} setHover={setHover}/>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"pl-0.5 text-green/80 font-bold"}>Machines On Disks</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <MachinesOnDisks dataServers={response.data} hover={hover} setHover={setHover}/>
                        }
                    </div>
                </div>
            </div>
        </Card>
    );
}

type DataProps = {
    dataServers: any[],
    hover?: string
    setHover?: (value: string | undefined) => void
}

const ActiveMachines: React.FC<DataProps> = ({dataServers, hover, setHover}) => {

    const {onOpen} = useModal();

    const handleOpenMachinesModal = (data: any) => {
        onOpen('active-machines', {dataServer: data});
    }

    const handleOpenChangeServerModal = (data: any) => {
        onOpen('change-server', {dataServer: data});
    }

    return (
        <div key={"ActiveMachines"} className={"flex flex-col"}>
            {dataServers.map((item: any, index) => (
                <div
                    key={index}
                    className={`grid grid-cols-[1.5rem,1fr,1fr] gap-1 items-center rounded-md transition-all ${hover === item.name_server ? 'bg-green/10' : ''}`}
                    onMouseEnter={() => setHover && setHover(item.name_server)}
                    onMouseLeave={() => setHover && setHover(undefined)}
                >
                    <div className={"flex flex-row"}>
                        <ActionTooltip label={"Open detail server data"}>
                            <div
                                className={"flex p-1 hover:bg-green/50 rounded-md cursor-pointer transition-all"}
                                onClick={() => handleOpenChangeServerModal(item)}
                            >
                                <ServerIcon
                                    className={`${item.response ? 'text-gray-dark dark:text-white' : 'text-red'}`}
                                    size={15}/>
                            </div>
                        </ActionTooltip>
                    </div>
                    <span className={`text-gray-dark dark:text-white text-xs font-bold`}>
                        {item.name_server}
                    </span>
                    <div className={"flex flex-row"}>
                        <ActionTooltip label={"Open detail active machines"}>
                            <div
                                className={"flex pl-2 pr-2 p-0.5 hover:bg-green/50 rounded-md cursor-pointer transition-all min-w-[35px]"}
                                onClick={() => handleOpenMachinesModal(item)}
                            >
                            <span className={"font-bold text-sm text-gray-dark dark:text-white"}>
                                {item.active.length}
                            </span>
                                {item.active_recovery.length > 0 && (
                                    <span className={"font-bold text-sm text-gray-dark dark:text-white"}>
                                {item.active_recovery.length}
                            </span>
                                )}
                            </div>
                        </ActionTooltip>
                    </div>
                </div>
            ))}
        </div>
    )
}

const MaxStartMachines: React.FC<DataProps> = ({dataServers, hover, setHover}) => {
    return (
        <div key={"MaxStartMachines"} className={"flex flex-col"}>
            {dataServers.map((item: any, index) => (
                <div
                    key={index}
                    className={`grid grid-cols-[7rem,1fr,1fr,1fr,1fr] gap-1 p-0.5 pl-1 items-center transition-all rounded-md ${hover === item.name_server ? 'bg-green/10' : ''}`}
                    onMouseEnter={() => setHover && setHover(item.name_server)}
                    onMouseLeave={() => setHover && setHover(undefined)}
                >
                    <span className={`text-gray-dark dark:text-white text-xs font-bold`}>
                        {item.name_server}
                    </span>
                    {item.disks.map((disk: any) => (
                        <div key={disk.name} className={"flex flex-row"}>
                            <span
                                className={"font-bold text-sm text-gray-dark dark:text-white"}>{disk.max_machine}</span>
                        </div>
                    ))}

                </div>
            ))}
        </div>
    )
}

const CountAllMachine: React.FC<DataProps> = ({dataServers, hover, setHover}) => {
    return (
        <div key={"CountAllMachine"} className={"flex flex-col"}>
            {dataServers.map((item: any, index) => (
                <div
                    key={index}
                    className={`grid grid-cols-[7rem,1fr] gap-1 p-0.5 pl-1 items-center transition-all rounded-md ${hover === item.name_server ? 'bg-green/10' : ''}`}
                    onMouseEnter={() => setHover && setHover(item.name_server)}
                    onMouseLeave={() => setHover && setHover(undefined)}
                >
                    <span className={`text-gray-dark dark:text-white text-xs font-bold`}>
                        {item.name_server}
                    </span>

                    <div className={"flex flex-row"}>
                        <span className={"font-bold text-sm text-gray-dark dark:text-white"}>{item.all}</span>
                    </div>

                </div>
            ))}
        </div>
    )
}

const MachinesOnDisks: React.FC<DataProps> = ({dataServers, hover, setHover}) => {
    return (
        <div key={"MachinesOnDisks"} className={"flex flex-col"}>
            {dataServers.map((item: any, index) => (
                <div
                    key={index}
                    className={`grid grid-cols-[7rem,1fr,1fr,1fr,1fr] gap-1 p-0.5 pl-1 items-center transition-all rounded-md ${hover === item.name_server ? 'bg-green/10' : ''}`}
                    onMouseEnter={() => setHover && setHover(item.name_server)}
                    onMouseLeave={() => setHover && setHover(undefined)}
                >
                    <span className={`text-gray-dark dark:text-white text-xs font-bold`}>
                        {item.name_server}
                    </span>

                    {item.disks.map((disk: any) => (
                        <div key={disk.name} className={"flex flex-row"}>
                            <span
                                className={"font-bold text-sm text-gray-dark dark:text-white"}>{disk.count_machine}</span>
                        </div>
                    ))}

                </div>
            ))}
        </div>
    )
}

export {MachineData}