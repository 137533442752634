import React, {useEffect, useState} from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {LocaleData, transformJsonToLocaleDataList} from "../../lib/transform-json";
import {LocaleItem, PlaceholderLocaleItem} from "./locale-item";
import {Button} from "../../components/shared/button";
import {useModal} from "../../hooks/use-modal-store";

export const LocaleScene: React.FC = () => {

    const {translate} = useLanguage();
    const {onOpen, isOpen, data} = useModal();

    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState<boolean>(true);
    const [locales, setLocales] = useState<LocaleData[]>([]);

    const loadData = () => {
        const response = axiosPrivate.get('/locales/list');

        response.then((response) => {
            if (response.status === 200) {
                setLocales(transformJsonToLocaleDataList(response.data.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadData();
    }, [axiosPrivate]);

    useEffect(() => {
        if (!isOpen && data.success) {
            loadData();
        }
    }, [isOpen, data]);

    return (
        <div className={"flex flex-col gap-4 w-full"}>

            <div className={"flex flex-row justify-between"}>
                <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('locale.title-page')}</span>
                <Button onClick={() => onOpen('locale-add')}>{translate('locale.add-locale')}</Button>
            </div>

            <Card>
                <div
                    className={`grid ${locales.length === 0 && !loading ? 'grid-cols-1' : 'lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2'} gap-4`}>
                    {loading ? <PlaceholderLocaleItem/> : locales.length === 0 ?
                        <span className={"w-full text-center font-bold text-gray-dark dark:text-white p-2"}>No location found</span> :
                        locales.map((locale, index) => (
                                <LocaleItem key={index} data={locale}/>
                            )
                        )}
                </div>
            </Card>
        </div>
    );
}