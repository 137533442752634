import React, {useEffect, useState} from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {useModal} from "../../hooks/use-modal-store";
import {useToast} from "../../contexts/ToastContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {Card} from "../../components/shared/card";
import {Button} from "../../components/shared/button";
import {TabsContainer} from "../../components/shared/tabs-container";
import {Input} from "../../components/shared/Input";
import {ItemAppCard, ItemAppCardLoader} from "./app-item";
import {AppData, transformJsonToAppData, transformJsonToAppDataList} from "../../lib/transform-json";
import {PlusCircle, SearchIcon} from "lucide-react";

export default function AppsScene() {

    const axiosPrivate = useAxiosPrivate();

    const {translate} = useLanguage()

    const {add} = useToast();

    const [loading, setLoading] = useState<boolean>(false);

    const [apps, setApps] = useState<AppData[]>([]);
    const [response, setResponse] = useState<AppData[]>([]);

    const tabs = ['ALL', 'ACTIVE', 'PENDING', 'BANNED'];
    const [tab, setTab] = useState<string>(tabs[0]);

    const [search, setSearch] = useState<string>('');

    const {isOpen, onOpen, data} = useModal();

    const handlerAddApp = () => {
        onOpen('add-app');
    }

    const handlerDeleteApp = (item: AppData) => {
        axiosPrivate.delete(`/apps/delete?auid=${item?.auid}`)
            .then((response) => {
                if (response.status === 200) {
                    add({type: 'info', message: item.app_id + ' is deleted'});
                    setResponse(prevState => prevState.filter(app => app.auid !== item.auid));
                }
            }).catch((error) => {
        });
    }

    const openDeleteModal = (item: AppData) => {
        onOpen(
            'delete-universal',
            {
                deleteData:
                    {
                        title: translate('def.delete') + '?',
                        description: translate('apps.modal.delete-description'),
                        button_delete: translate('def.delete'),
                        button_close: translate('def.cancel')
                    }
            },
            {onConfirm: () => handlerDeleteApp(item)}
        );
    }

    const handlerUpdateApp = (item: AppData) => {

        setResponse(prevState => prevState.map((app) => {
            if (app.auid === item.auid) {
                return {...app, loading: true};
            }
            return app;
        }));

        const response = axiosPrivate.post('/apps/update', {
            app_id: item.app_id,
        });

        response.then((response) => {
            if (response.status === 200 && response.data.data) {

                let appData = transformJsonToAppData(response.data.data);

                setResponse(prevState => prevState.map((app) => {
                    if (app.auid === item.auid) {
                        return appData;
                    }
                    return app;
                }).sort((a, b) => {
                    // Сначала сортируем по статусу, чтобы 'ACTIVE' всегда был первым
                    if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') {
                        return -1;
                    }
                    if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') {
                        return 1;
                    }

                    // Если статусы одинаковые, сортируем по a.auid
                    if (a.auid < b.auid) {
                        return -1;
                    }
                    if (a.auid > b.auid) {
                        return 1;
                    }

                    return 0; // Если статусы и a.auid одинаковые, порядок не меняется
                }));

                if (response.data.data.status === 'BANNED' && item.status === 'ACTIVE') {
                    add({
                        message: item.app_id + ' was banned',
                        type: 'error',
                    });
                }

                if (response.data.data.status === 'ACTIVE' && item.status === 'PENDING') {
                    add({
                        message: item.app_id + ' was approved',
                        type: 'success',
                    });
                }
            }
        }).catch((error) => {
            console.log(error);
            setResponse(prevState => prevState.map((app) => {
                if (app.app_id === item.app_id) {
                    return {...app, loading: false};
                }
                return app;
            }));
        });

    }

    const loadApps = () => {
        setLoading(true);

        const response = axiosPrivate.get('/apps/list');

        response.then((response) => {
            if (response.status === 200 && response.data.data) {
                const appData = transformJsonToAppDataList(response.data.data);
                appData.sort((a, b) => {
                    // Сначала сортируем по статусу, чтобы 'ACTIVE' всегда был первым
                    if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') {
                        return -1;
                    }
                    if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') {
                        return 1;
                    }

                    // Если статусы одинаковые, сортируем по a.auid
                    if (a.auid < b.auid) {
                        return -1;
                    }
                    if (a.auid > b.auid) {
                        return 1;
                    }

                    return 0; // Если статусы и a.auid одинаковые, порядок не меняется
                })
                setResponse(appData);
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    const handleOpenKeys = (app: AppData) => {
        window.open(`/keywords${app.auid.length > 0 ? '?auid=' + app.auid : ''}`, "_blank");
    };

    const handlerOpenCreatePromote = (app: AppData) => {
        window.open(`/campaign/add${app.auid.length > 0 ? '?auid=' + app.auid : ''}`, "_blank");
    }

    useEffect(() => {
        setApps(
            response.filter(app => {
                const statusMatches = tab.toLowerCase() === 'all' || app.status.toLowerCase() === tab.toLowerCase();
                const searchLower = search.toLowerCase();
                const nameMatches = Object.values(app.details).some(detail => detail.name && detail.name.toLowerCase().includes(searchLower));
                const publisherMatches = Object.values(app.details).some(detail => detail.publisher && detail.publisher.toLowerCase().includes(searchLower));

                return statusMatches && (search === '' || nameMatches || publisherMatches);
            }).sort((app) => {
                if (app.status === 'ACTIVE') {
                    return -1;
                }
                return 1;
            }));
    }, [tab, response, search]);

    useEffect(() => {
        if (!isOpen && data.success) {
            loadApps();
        }
    }, [isOpen, data]);

    useEffect(() => {
        loadApps();
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('apps.title-page')}</span>

            <Card>
                <div className={"flex flex-row justify-between mb-2"}>
                    <Button className={"w-36"} onClick={handlerAddApp}>
                        <div className={"flex flex-row gap-2 w-full items-center justify-start"}>
                            <PlusCircle size={18}/>
                            <span className={"text-xs"}>{translate('apps.add-app')}</span>
                        </div>
                    </Button>
                    <div className={"flex w-52"}>
                        <Input
                            type={"text"}
                            label={translate('def.search')}
                            onChange={(e) => setSearch(e.target.value)}
                            icon={<SearchIcon size={20}/>}
                        />
                    </div>
                </div>
                <TabsContainer tabs={tabs} onTabChange={setTab}
                               classNameDivChildren={`grid ${apps.length === 0 && !loading ? 'grid-cols-1' : 'lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2'} gap-2 p-2`}>
                    {loading ?
                        <>
                            <ItemAppCardLoader/>
                            <ItemAppCardLoader/>
                            <ItemAppCardLoader/>
                            <ItemAppCardLoader/>
                        </>
                        : apps.length === 0 ?
                            <span className={"w-full text-center font-bold text-gray-dark dark:text-white p-2"}>{translate('apps.not-found')}</span> :
                            apps.map((app, index) => (
                                    <ItemAppCard key={index} data={app}
                                                 onClickEditKeywords={(app) => handleOpenKeys(app)}
                                                 onClickPromote={(app) => handlerOpenCreatePromote(app)}
                                                 onDelete={openDeleteModal}
                                                 onUpdate={handlerUpdateApp}/>
                                )
                            )}
                </TabsContainer>
            </Card>
        </div>
    )
}
