import {useLocation} from "react-router";
import {useLanguage} from "../../contexts/LanguageContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {CampaignData, transformJsonToCampaignData} from "../../lib/transform-json";
import React, {useEffect} from "react";
import {Card} from "../../components/shared/card";
import {useNavigate} from "react-router-dom";
import {CampaignItem, ItemCampaignCardLoader} from "./campaign-item";
import {DayItem, ItemDayCardLoader} from "./day-item";
import {Button} from "../../components/shared/button";
import {useAuth} from "../../contexts/AuthContext";
import {useToast} from "../../contexts/ToastContext";
import {useModal} from "../../hooks/use-modal-store";

type TypeActionCampaign = 'START' | 'PAUSE' | 'RESTART' | 'REMOVE';

export default function CampaignDetailScene() {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const {add} = useToast();

    const {user} = useAuth();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const cuidParam = searchParams.get('cuid') || '';

    const {isOpen, onOpen, data} = useModal();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [campaign, setCampaign] = React.useState<CampaignData>();

    const loadCampaign = () => {
        axiosPrivate.get(`/campaigns?cuid=${cuidParam}`).then((response) => {
            setLoading(false);
            if (response.status === 200 && response.data.data) {
                setCampaign(transformJsonToCampaignData(response.data.data));
            } else {
                navigate('/not-found');
            }
        }).catch((error) => {
            navigate('/not-found');
            setLoading(false);
        });
    }

    const handlerButtonCampaign = (type: TypeActionCampaign) => {
        function handlerDeleteCampaign() {
            axiosPrivate.delete(`/campaigns?cuid=${cuidParam}`)
                .then((response) => {
                    if (response.status === 200) {
                        add({type: 'info', message: translate('campaigns.detail.deleted')});
                        navigate('/campaign/list');
                    }
                }).catch((error) => {
                add({type: 'error', message: translate('campaigns.detail.deleted-error')});
            });
        }

        if (type === 'REMOVE') {
            onOpen(
                'delete-universal',
                {
                    deleteData:
                        {
                            title: translate('def.delete') + '?',
                            description: translate('campaigns.detail.delete-description'),
                            button_delete: translate('def.delete'),
                            button_close: translate('def.cancel')
                        }
                },
                {onConfirm: () => handlerDeleteCampaign()}
            );
        } else if (type === 'START') {
            axiosPrivate.patch(`/campaigns/start`, {
                id: cuidParam
            }).then((response) => {
                if (response.status === 200) {
                    add({type: 'info', message: translate('campaigns.detail.started')});
                    loadCampaign();
                }
            }).catch((error) => {
                console.log(error);
                add({type: 'error', message: translate('campaigns.detail.started-error')});
            });
        } else if (type === 'RESTART') {
            axiosPrivate.patch(`/campaigns/restart`, {
                id: cuidParam
            }).then((response) => {
                if (response.status === 200) {
                    add({type: 'info', message: translate('campaigns.detail.restarted')});
                    loadCampaign();
                }
            }).catch((error) => {
                console.log(error);
                add({type: 'error', message: translate('campaigns.detail.restarted-error')});
            });
        } else {
            axiosPrivate.patch(`/campaigns/pause`, {
                id: cuidParam
            }).then((response) => {
                if (response.status === 200) {
                    add({type: 'info', message: translate('campaigns.detail.paused')});
                    loadCampaign();
                }
            }).catch((error) => {
                console.log(error);
                add({type: 'error', message: translate('campaigns.detail.paused-error')});
            });
        }
    }

    const handleRestartFromThisDay = (id: string) => {
        axiosPrivate.patch(`/campaigns/restart/day`, {
            id: id,
        })
            .then((response) => {
                if (response.status === 200) {
                    add({type: 'info', message: translate('campaigns.detail.restarted')});
                    loadCampaign();
                }
            }).catch((error) => {
            add({type: 'error', message: translate('campaigns.detail.restarted-error')});
        });
    }

    useEffect(() => {
        loadCampaign();
    }, [axiosPrivate]);

    function handleDetailDay(id: string) {
        add({type: 'info', message: 'Coming soon'});
    }

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('campaigns.detail.title-page')}</span>

            <Card>
                <div className={"flex flex-col"}>
                    {loading && (
                        <div>
                            <div className={"grid lg:grid-cols-3 sm:grid-cols-2 md:grid-cols-2 gap-2"}>
                                <ItemCampaignCardLoader/>
                            </div>
                            <div className={"flex flex-col gap-2 rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border p-4 mt-4"}>
                                <div className={"grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-2"}>
                                    <ItemDayCardLoader/>
                                    <ItemDayCardLoader/>
                                    <ItemDayCardLoader/>
                                    <ItemDayCardLoader/>
                                    <ItemDayCardLoader/>
                                    <ItemDayCardLoader/>
                                </div>
                            </div>
                        </div>
                    )}

                    {!loading && (
                        <div>
                            <div className={"flex justify-between w-full gap-4"}>
                                <div className={"w-[500px]"}>
                                    <CampaignItem campaignData={campaign as CampaignData}/>
                                </div>

                                {(campaign?.user == null || campaign.user.uuid === user?.uuid) && (
                                    <div className={"flex flex-col gap-2"}>
                                        {(campaign?.status === 'PENDING' || campaign?.status === 'FINISHED') && (
                                            <Button onClick={() => handlerButtonCampaign("RESTART")}>
                                                {translate('campaigns.detail.restart')}
                                            </Button>
                                        )}
                                        {(campaign?.status === 'IN_PROGRESS') && (
                                            <Button onClick={() => handlerButtonCampaign("PAUSE")}
                                                    className={"bg-orange-dark/60 hover:bg-orange-dark"}>
                                                {translate('campaigns.detail.pause')}
                                            </Button>
                                        )}
                                        {(campaign?.status === 'PENDING') && (
                                            <Button onClick={() => handlerButtonCampaign("START")}
                                                    className={"bg-orange-dark/60 hover:bg-orange-dark"}>
                                                {translate('campaigns.detail.start')}
                                            </Button>
                                        )}
                                        <Button onClick={() => handlerButtonCampaign("REMOVE")}
                                                className={"bg-red-dark/60 hover:bg-red-dark"}>
                                            {translate('campaigns.detail.remove')}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className={"flex flex-col gap-2 rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border p-4 mt-4"}>
                                <div className={"grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-2"}>
                                    {!loading && (
                                        campaign?.days.map((day, index) => (
                                            (campaign?.user == null || campaign.user.uuid === user?.uuid) ? (
                                                <DayItem
                                                    key={"day_" + index}
                                                    dayData={day}
                                                    onClickRestart={(id) => handleRestartFromThisDay(id)}
                                                    onClickDetail={(id) => handleDetailDay(id)}
                                                />
                                            ) : (
                                                <DayItem
                                                    key={"day_" + index}
                                                    dayData={day}
                                                    onClickDetail={(id) => handleDetailDay(id)}
                                                />
                                            )
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        </div>
    )
}
