import React, {useEffect, useState} from "react";
import {AlertTriangle} from "lucide-react";
import {cn} from "../../lib/utils";
import {ActionTooltip} from "./tooltip";
import {useLanguage} from "../../contexts/LanguageContext";

interface InputProps {
    id?: string;
    type: 'text' | 'password' | 'email' | 'number' | 'url' | 'search' | 'tel';
    label?: string;
    value?: any;
    required?: boolean;
    error?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disable?: boolean
    icon?: React.ReactNode
}

const Input: React.FC<InputProps> = (
    {
        id,
        type,
        label,
        value,
        error,
        onChange,
        disable,
        ...props
    }) => {

    const [isActive, setIsActive] = useState(!!value);
    const [shake, setShake] = useState(false);
    const {translate} = useLanguage();

    useEffect(() => {
        if (error !== undefined && error !== null && error !== '') {
            setShake(true);
            setTimeout(() => setShake(false), 300);
        }
    }, [error]);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>, focused: boolean) => {
        // Проверяем, находится ли фокус на элементе ввода или есть ли значение у поля ввода.
        if (focused || e.target.value) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }

        // Проверка на required и пустое значение при потере фокуса
        if (!focused && props.required && !e.target.value) {
            setShake(true);
            setTimeout(() => setShake(false), 300);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (type === 'number') {
            e.target.value = e.target.value.replace(/^0+(?!\.|$)/, '');
        }

        onChange && onChange(e);
    };

    return (
        <div className={`relative w-full ${shake && error ? 'animate-[shake-x_0.3s]' : ''}`}>
            <input id={id}
                   disabled={disable}
                   type={type}
                   onChange={handleInputChange}
                   onFocus={(e) => handleFocus(e, true)}
                   onBlur={(e) => handleFocus(e, false)}
                   value={value}
                   className={cn("p-2 w-full border-2 bg-white dark:bg-gray-dark rounded-md text-gray dark:text-white text-xs font-bold outline-none", (error ? "border-red/80" : disable ? "border-gray/50 cursor-no-drop" : "border-green/50"))}
                   {...props}/>
            {error && (
                <ActionTooltip label={translate(error)} side={"bottom"}>
                    <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                        <AlertTriangle size={20} className={`text-red/60`}/>
                    </div>
                </ActionTooltip>
            )}
            {label && <span
                className={`absolute ${props.icon && !isActive ? 'left-8' : 'left-2'} p-1 top-0 bg-white dark:bg-gray-dark transition-all transform ${isActive ? `translate-y-[-110%] scale-75 ${error ? 'text-red/80' : 'text-green/50'}` : 'translate-y-[-50%] scale-100 text-gray dark:text-white'} text-xs rounded-md font-bold pointer-events-none`}
                style={{top: "50%", transformOrigin: "0 0"}}>{label} {props.required && <span className="text-red-600">*</span>}</span>}
            {props.icon && !isActive && (
                <div
                    className={`absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-dark dark:text-white transition-all`}>
                    {props.icon}
                </div>
            )}
        </div>
    );
}

export {Input};
