import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";

export const CreateServerModal = () => {
    const {isOpen, onClose, type} = useModal();

    const isModalOpen = isOpen && type === "create-server";

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0 overflow-hidden">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-2xl text-center font-bold text-gray-dark dark:text-white">
                        Customize your server
                    </DialogTitle>
                    <DialogDescription className="text-center text-gray-dark dark:text-white">
                        Give your server a personality with a name and an image. You can always change it later.
                    </DialogDescription>
                </DialogHeader>

                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button>
                        Create
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}