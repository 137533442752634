import React, {useEffect, useState} from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {useModal} from "../../hooks/use-modal-store";
import {Card} from "../../components/shared/card";
import {Input} from "../../components/shared/Input";
import {Button} from "../../components/shared/button";
import {Separator} from "../../components/shared/separator";
import {useToast} from "../../contexts/ToastContext";
import {useNavigate} from "react-router-dom";
import {Checkbox} from "../../components/shared/checkbox";
import {ServerData} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useLocation} from "react-router";


const ServerScene: React.FC = () => {

    const {translate} = useLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const {add} = useToast();

    const axiosPrivate = useAxiosPrivate();

    const {onOpen} = useModal();

    const searchParams = new URLSearchParams(location.search);

    const id = searchParams.get('id');

    const [loading, setLoading] = React.useState<boolean>(id !== null);

    const [error, setError] = useState<Record<string, { value: string | undefined }>>({});

    const [disks, setDisks] = useState<{ checked: boolean, name: string }[]>([
        {checked: true, name: 'home'},
        {checked: false, name: 'md2'},
        {checked: false, name: 'md3'},
        {checked: false, name: 'md4'},
    ]);

    useEffect(() => {
        const updatedDisks = disks
            .filter(disk => disk.checked)
            .map(disk => ({
                id: null,
                name: disk.name,
                count_machine: 0,
                start_machine: 0
            }));

        setForm(prevForm => ({
            ...prevForm,
            disks: updatedDisks
        }));
    }, [disks]);

    const [form, setForm] = useState<ServerData>({
        id: null,
        name: '',
        ip: '',
        disks: [],
        type: 'DEDIK',
        server_params: {
            reboot: false,
            task_in_queue: 2,
            time_to_bootloop: 15,
            time_between_start: 10,
            version_bot: 1000,
            version_services: '{change_this}',
            version_market: '{change_this}',
            update_link: 'http://144.76.225.13/{change_this}'
        }
    });

    const handleRequest = () => {

        setError({});
        setLoading(true);
        let hasError = false;

        if (form.name === '') {
            hasError = true;
            setError(prev => ({...prev, name: {value: translate('name_require')}}));
        }

        if (form.ip === '') {
            hasError = true;
            setError(prev => ({...prev, ip: {value: translate('ip_require')}}));
        } else if (!/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(form.ip!!)) {
            hasError = true;
            setError(prev => ({...prev, ip: {value: translate('ip_invalid')}}));
        }

        if (form.server_params.update_link === '') {
            hasError = true;
            setError(prev => ({...prev, link_update: {value: translate('link_update_require')}}));
        } else if (!/^(http|https):\/\/[^ "]+$/.test(form.server_params.update_link!!)) {
            hasError = true;
            setError(prev => ({...prev, update_link: {value: translate('link_update_invalid')}}));
        } else if (form.server_params.update_link!!.includes('{change_this}')) {
            hasError = true;
            setError(prev => ({...prev, update_link: {value: translate('link_update_invalid')}}));
        }

        if (form.disks.length === 0) {
            add({
                type: "error",
                message: translate('one_disk_required'),
            })
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        axiosPrivate.post('/servers/add', form)
            .then(response => {
                if (response.status === 200) {
                    add({
                        type: "success",
                        message: "Server created",
                        duration: 2000,
                    })
                    setTimeout(() => {
                        navigate('/servers/list');
                        setLoading(false);
                    }, 500);
                }
            }).catch(error => {
            console.log(error);
            setLoading(false);
        })
    }

    useEffect(() => {
        if (id) {
            axiosPrivate.get(`/servers/?id=${id}`)
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data.data);
                        setTimeout(() => {
                            setForm(response.data.data);
                            setLoading(false);
                        }, 1500);
                    } else {
                        add({
                            type: "error",
                            message: "Server not found",
                            duration: 2000,
                        });
                        setLoading(false);
                    }
                }).catch(error => {
                console.log(error);
                setLoading(false);
            })
        }
    }, []);

    const handleDelete = () => {
        navigate('/servers/list');
    }

    const openDeleteModal = () => {
        onOpen(
            'delete-universal',
            {
                deleteData: {
                    title: 'Delete Server?',
                    description: 'Are you sure you want to delete this server?',
                    button_close: 'Cancel',
                    button_delete: 'Delete'
                }
            },
            { onConfirm: handleDelete }
        );
    }

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('server-add')}</span>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
                <Card isExpanded={false} title={"Main Data Server"}>
                    <Input
                        label={"Name Server"}
                        type={"text"}
                        value={form.name}
                        onChange={(e) => setForm({...form, name: e.target.value})}
                        error={error.name?.value}
                        disable={loading}
                    />
                    <Input
                        label={"IP Server"}
                        type={"text"}
                        value={form.ip}
                        onChange={(e) => setForm({...form, ip: e.target.value})}
                        error={error.ip?.value}
                        disable={loading}
                    />
                    <div className="flex flex-col gap-1">
                        <span className="text-xs font-bold text-gray-dark dark:text-white">Reboot every day?</span>
                        <Checkbox
                            label={translate('yes-no')}
                            defaultChecked={form.server_params.reboot || false}
                            onCheck={(checked) => setForm({
                                ...form,
                                server_params: {
                                    ...form.server_params,
                                    reboot: checked
                                }
                            })}
                            disable={loading}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <span className="text-xs font-bold text-gray-dark dark:text-white">Disks</span>
                        <div className="flex flex-row gap-2">
                            {disks.map((disk, index) => (
                                <Checkbox
                                    key={index}
                                    label={disk.name}
                                    defaultChecked={disk.checked}
                                    disable={loading}
                                    onCheck={(checked) => {
                                        const newDisks = disks.map((item, idx) =>
                                            idx === index ? {...item, checked: checked} : item
                                        );
                                        setDisks(newDisks);
                                    }}/>
                            ))}
                        </div>
                    </div>

                </Card>
                <Card isExpanded={false} title={"Machine data"}>
                    {disks.some(disk => disk.checked) && (
                        <div>
                            <div className="grid grid-cols-4 gap-2">
                                {form.disks.map((disk, index) => (
                                    <div key={index}>
                                        <div className="flex flex-col gap-2">
                                            <span
                                                className="text-xs font-bold mb-1 ml-1 text-gray-dark dark:text-white">
                                                {disk.name}
                                            </span>
                                            <Input
                                                label={"Count"}
                                                value={disk.count_machine}
                                                onChange={(e) =>
                                                    setForm(prevForm => ({
                                                        ...prevForm,
                                                        disks: prevForm.disks.map(d =>
                                                            d.name === disk.name
                                                                ? {...d, count_machine: Number(e.target.value)}
                                                                : d
                                                        )
                                                    }))}
                                                disable={loading}
                                                type={"number"}/>
                                            <Input
                                                label={"Max Start"}
                                                value={disk.start_machine}
                                                onChange={(e) =>
                                                    setForm(prevForm => ({
                                                        ...prevForm,
                                                        disks: prevForm.disks.map(d =>
                                                            d.name === disk.name
                                                                ? {...d, start_machine: Number(e.target.value)}
                                                                : d
                                                        )
                                                    }))}
                                                disable={loading}
                                                type={"number"}/>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <Separator className="mt-4" orientation={"horizontal"}/>
                        </div>
                    )}

                    <Input
                        label={"Task In Queue"}
                        value={form.server_params.task_in_queue}
                        onChange={(e) =>
                            setForm({
                                ...form,
                                server_params: {
                                    ...form.server_params,
                                    task_in_queue: Number(e.target.value)
                                }
                            })}
                        disable={loading}
                        type={"number"}/>
                    <Input
                        label={"Time to Bootloop (sec)"}
                        value={form.server_params.time_to_bootloop}
                        onChange={(e) =>
                            setForm({
                                ...form,
                                server_params: {
                                    ...form.server_params, time_to_bootloop: Number(e.target.value)
                                }
                            })}
                        disable={loading}
                        type={"number"}/>
                    <Input
                        label={"Time between start (sec)"}
                        value={form.server_params.time_between_start}
                        onChange={(e) => setForm({
                            ...form,
                            server_params: {
                                ...form.server_params, time_between_start: Number(e.target.value)
                            }
                        })}
                        disable={loading}
                        type={"number"}/>

                </Card>
                <Card isExpanded={false} title={translate('text-bot-data')}>
                    <Input
                        label={'Version Bot'}
                        value={form.server_params.version_bot}
                        onChange={(e) => setForm({
                            ...form,
                            server_params: {
                                ...form.server_params, version_bot: Number(e.target.value)
                            }
                        })}
                        disable={loading}
                        type={"number"}/>
                    <Input
                        label={'Version Services'}
                        value={form.server_params.version_services}
                        onChange={(e) => setForm({
                            ...form,
                            server_params: {
                                ...form.server_params, version_services: e.target.value
                            }
                        })}
                        disable={loading}
                        type={"text"}/>
                    <Input
                        label={'Version Market'}
                        value={form.server_params.version_market}
                        onChange={(e) => setForm({
                            ...form,
                            server_params: {
                                ...form.server_params, version_market: e.target.value
                            }
                        })}
                        disable={loading}
                        type={"text"}/>
                    <Input
                        label={'Link Update'}
                        value={form.server_params.update_link}
                        onChange={(e) => setForm({
                            ...form,
                            server_params: {
                                ...form.server_params, update_link: e.target.value
                            }
                        })}
                        disable={loading}
                        error={error.update_link?.value}
                        type={"url"}/>
                </Card>
            </div>

            <Separator orientation={"horizontal"}/>

            <div className="flex h-12 justify-between">
                <div/>
                <div className="flex flex-row gap-3">
                    {form.id && (
                        <Button className="bg-red/50 h-10 hover:bg-red/90"
                                type={"button"}
                                onClick={openDeleteModal}
                                disabled={loading}>
                            {translate('delete')}
                        </Button>
                    )}

                    <Button className="h-10"
                            type={"button"}
                            onClick={handleRequest}
                            disabled={loading}>
                        {translate(form.id ? 'update' : 'create')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ServerScene
