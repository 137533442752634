import React, {useEffect, useState} from "react";
import {DiskData, ServerData} from "../../../lib/transform-json";
import {useLanguage} from "../../../contexts/LanguageContext";
import {Input} from "../../shared/Input";
import {X} from "lucide-react";

type RaspberryPiServerType = {
    serverData: ServerData;
    setForm: (form: ServerData) => void;
    loading: boolean;
    error: Record<string, { value: string | undefined }>;
}

export const RaspberryPiServer: React.FC<RaspberryPiServerType> = ({serverData, setForm, loading, error}) => {

    const {translate} = useLanguage();

    const [disks, setDisks] = useState<DiskData[]>([]);

    const addDisk = () => {
        setDisks([...disks, {id: disks.length, count_machine: 0, name: ""}]);
    }

    //TODO надо будет учитывать елси я хочу изсенять сервер
    useEffect(() => {
        setForm(({
            ...serverData,
            disks: disks.map(disk => ({
                count_machine: disk.count_machine,
                name: disk.name,
                start_machine: 0
            }))
        }));
    }, [disks]);

    return (
        <div className={"flex flex-col gap-1"}>
            <div className={"grid grid-cols-2 flex-row gap-2"}>
                <div className="grid">
                    <div className={"flex flex-col gap-3"}>
                        <Input
                            label={"Name Server"}
                            type={"text"}
                            value={serverData.name}
                            onChange={(e) => setForm({...serverData, name: e.target.value})}
                            error={error.name?.value}
                            disable={loading}
                        />
                        <Input
                            label={"Identifier Server"}
                            type={"text"}
                            value={serverData.identifier}
                            onChange={(e) => setForm({...serverData, identifier: e.target.value})}
                            error={error.identifier?.value}
                            disable={loading}
                        />
                        <Input
                            label={"Tasks In Queue"}
                            value={serverData.server_params.task_in_queue || 0}
                            onChange={(e) =>
                                setForm({
                                    ...serverData,
                                    server_params: {
                                        ...serverData.server_params,
                                        task_in_queue: Number(e.target.value)
                                    }
                                })}
                            disable={loading}
                            type={"number"}/>
                    </div>
                </div>
                <div className="grid">
                    <div className={"flex flex-col gap-3"}>
                        <span className={"text-xs text-gray-dark dark:text-white font-bold"}>{translate('servers.modal.disks')}</span>
                        <div className={"flex flex-col gap-3"}>
                            <div className="grid grid-cols-2 gap-2">
                                {disks.map((disk, index) => (
                                    <div key={index} className={"bg-green/10 rounded-md p-2"}>
                                        <div className="flex flex-col gap-3">
                                            <div className={"flex flex-row justify-end"}>
                                                <div
                                                    onClick={() => setDisks(disks.filter(d => d.id !== disk.id))}
                                                    className={"p-0.5 rounded-md bg-red/50 hover:bg-red/80 cursor-pointer transition-all"}>
                                                    <X className={"text-gray-dark dark:text-white"} size={14}/>
                                                </div>
                                            </div>
                                            <Input
                                                label={"Name"}
                                                value={disk.name}
                                                onChange={(e) =>
                                                    setDisks(disks.map(d =>
                                                        d.id === disk.id
                                                            ? { ...d, name: e.target.value }  // обновляем диск, если id совпадает
                                                            : d  // возвращаем остальные диски без изменений
                                                    ))
                                                }
                                                disable={loading}
                                                type={"text"}/>
                                            <Input
                                                label={"Count machines"}
                                                value={disk.count_machine || 0}
                                                onChange={(e) =>
                                                    setDisks(disks.map(d =>
                                                        d.id === disk.id
                                                            ? { ...d, count_machine: Number(e.target.value) }  // обновляем диск, если id совпадает
                                                            : d  // возвращаем остальные диски без изменений
                                                    ))
                                                }
                                                disable={loading}
                                                type={"number"}/>
                                        </div>
                                    </div>
                                ))}
                                <div
                                    onClick={() => addDisk()}
                                    className={"h-fit bg-green/50 hover:bg-green/80 rounded-md p-2 cursor-pointer text-xs text-gray-dark dark:text-white transition-all"}>
                                    {translate('def.add')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
