import React, {useEffect, useState} from "react";
import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";
import {Input} from "../shared/Input";
import {useToast} from "../../contexts/ToastContext";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../shared/select";
import {ErrorData, LocaleData, transformJsonToLocaleDataList} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {AxiosError} from "axios";
import {useLanguage} from "../../contexts/LanguageContext";

export const AddProxyConfigModal = () => {
    const {isOpen, onClose, type, data} = useModal();

    const isModalOpen = isOpen && type === "add-proxy-config";

    const {add} = useToast();
    const {translate} = useLanguage();

    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<Record<string, { value: string }>>({});
    const [errorResponse, setErrorResponse] = useState<string | null>(null);
    const [form, setForm] = useState({
        id: null,
        base_url: '',
        query_url: '',
        service_type: '',
        proxy_type: '',
        auth_params: {
            auth_key: '',
            auth_value: '',
        },
        other_params: '',
        delay_check: 30,
        locale: {
            id: 0,
        }
    });

    const [locales, setLocales] = useState<LocaleData[]>([]);
    const [search, setSearch] = useState<string>('');

    const loadData = () => {
        const response = axiosPrivate.get('/locales/list');

        response.then((response) => {
            if (response.status === 200) {
                setLocales(transformJsonToLocaleDataList(response.data.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    const handlerAddApp = () => {

        setError({});
        setLoading(true);
        let hasError = false;

        if (form.service_type === '') {
            setError(prev => ({...prev, service_type: {value: 'service_type_is_require'}}));
            hasError = true;
        }

        if (form.proxy_type === '') {
            setError(prev => ({...prev, proxy_type: {value: 'proxy_type_is_require'}}));
            hasError = true;
        }

        if (form.base_url === '') {
            setError(prev => ({...prev, base_url: {value: 'base_url_is_require'}}));
            hasError = true;
        }

        if (form.query_url === '') {
            setError(prev => ({...prev, query_url: {value: 'query_url_is_require'}}));
            hasError = true;
        }

        if (form.locale === null) {
            setError(prev => ({...prev, locale: {value: 'locale_is_require'}}));
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        axiosPrivate.put('/proxies/configs/save', form)
            .then((response) => {
                if (response.status === 200) {
                    setForm({
                        id: null,
                        base_url: '',
                        query_url: '',
                        service_type: '',
                        proxy_type: '',
                        auth_params: {
                            auth_key: '',
                            auth_value: '',
                        },
                        other_params: '',
                        delay_check: 30,
                        locale: {
                            id: 0,
                        }
                    });

                    add({
                        type: "success",
                        message: "Config added successfully",
                    });
                    setLoading(false);
                    data.success = true;
                    onClose();
                } else {
                    console.log(response.data);
                }
                setLoading(false);
            }).catch((error) => {
                const errorAxios = error as AxiosError<ErrorData>;

                if (errorAxios.response) {
                    if (errorAxios.response.status === 400) {
                        if (errorAxios.response.data.error_message)
                            setErrorResponse(errorAxios.response.data.error_message);
                        else
                            setErrorResponse("Error while adding app");
                    }
                } else {
                    console.log(error);
                }
                setLoading(false);
            }
        );
    }

    const handleClose = () => {
        onClose();
    }

    useEffect(() => {
        if (isOpen)
            if (!search) {
                loadData();
            } else {
                setLocales(locales.filter(locale => locale.country.toLowerCase().includes(search.toLowerCase())));
            }
    }, [search]);

    useEffect(() => {
        if (isOpen)
            loadData();
    }, [isOpen]);

    const [shake, setShake] = useState(false);

    useEffect(() => {
        if (error.locale) {
            setShake(true);
            setTimeout(() => setShake(false), 300);
        }
    }, [error.locale]);

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        Add new proxy config
                    </DialogTitle>
                </DialogHeader>
                <div className="max-h-144 overflow-scroll">
                    <div className="flex flex-col gap-2 p-6">
                        <span className="text-xs font-bold text-gray/50">Please enter params proxy service</span>

                        <div className={"w-full"}>
                            <Input type={"text"}
                                   label={translate('proxy_config_base_url')}
                                   disable={loading}
                                   value={form.base_url}
                                   error={error.base_url?.value}
                                   onChange={(e) => setForm(prev => ({...prev, base_url: e.target.value}))}/>
                        </div>

                        <div className={"w-full"}>
                            <Input type={"text"}
                                   label={translate('proxy_config_query_url')}
                                   disable={loading}
                                   value={form.query_url}
                                   error={error.query_url?.value}
                                   onChange={(e) => setForm(prev => ({...prev, query_url: e.target.value}))}/>
                        </div>

                        <div className={"w-full"}>
                            <Input type={"text"}
                                   label={translate('proxy_config_query_url')}
                                   disable={loading}
                                   value={form.query_url}
                                   error={error.query_url?.value}
                                   onChange={(e) => setForm(prev => ({...prev, query_url: e.target.value}))}/>
                        </div>

                        <span className="text-xs font-bold text-gray/50">{translate('country_of_proxy')}:</span>

                        <div className={"w-52"}>
                            <Select
                                disabled={loading}
                                onValueChange={(value) => setForm(prev =>
                                    ({
                                        ...prev, locale: {
                                            ...prev.locale,
                                            id: Number(value),
                                        }
                                    }))}
                            >

                                <SelectTrigger
                                    className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none ${shake ? 'animate-[shake-x_0.3s]' : ''}`}>
                                    <SelectValue placeholder="Country Select"/>
                                </SelectTrigger>

                                <SelectContent className={"flex flex-col"}>

                                    <Input type={"text"}
                                           label={"Search"}
                                           disable={loading}
                                           value={search}
                                           onChange={(e) => setSearch(e.target.value)}/>

                                    <div className={"h-52 overflow-scroll pt-2"}>
                                        {Object.values(locales).map((type) => (
                                            <SelectItem
                                                key={type.country + "-" + type.language + "-" + type.type}
                                                value={type.id.toString()}
                                                className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs"
                                            >
                                                <div className={"flex flex-row gap-2"}>
                                                    <img alt={type.country.toUpperCase()} className={"h-4 w-4"}
                                                         src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${type.country.toUpperCase()}.svg`}/>
                                                    {type.type && (
                                                        <span>{`${type.country.toLowerCase()}-${type.language.toUpperCase()} ${type.type.toUpperCase() === 'TEST' ? '(TEST)' : ''}`}</span>
                                                    )}
                                                </div>
                                            </SelectItem>
                                        ))}
                                    </div>
                                </SelectContent>
                            </Select>
                        </div>
                        {errorResponse && (
                            <span className="text-xs font-bold text-red">Error: {errorResponse}</span>
                        )}
                    </div>
                </div>
                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button onClick={handlerAddApp} disabled={loading}>
                        {translate('add')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
