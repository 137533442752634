import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';

interface LanguageContextType {
    translations: TranslationValue;
    language: string;
    setLanguage: React.Dispatch<React.SetStateAction<string>>;
    translate: (key: string) => string;
}

interface LanguageProviderProps {
    children: React.ReactNode;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

type TranslationValue = string | { [key: string]: TranslationValue };

export const LanguageProvider: React.FC<LanguageProviderProps> = ({children}) => {
    const storedLanguage = localStorage.getItem('language') || 'en'; // default language
    const [language, setLanguage] = useState<string>(storedLanguage);
    const [translations, setTranslations] = useState<TranslationValue>({});
    const [defaultTranslations, setDefaultTranslations] = useState<TranslationValue>({});

    useEffect(() => {
        const load = async () => {
            try {
                if (Object.keys(defaultTranslations).length === 0) {
                    const defaultData = await loadTranslations('en');
                    setDefaultTranslations(defaultData);
                }
                const data = await loadTranslations(language);
                setTranslations(data);
            } catch (err) {
                console.log(err);
            }

            localStorage.setItem('language', language);
        };

        load();
    }, [language]);

    const translate = (key: string): string => {
        const parts = key.split('.');
        let current: TranslationValue | null = translations;
        let defaultCurrent: TranslationValue | null = defaultTranslations;

        // Ищем в основных переводах
        for (let part of parts) {
            if (current && typeof current === 'object' && current[part] !== undefined) {
                current = current[part];
            } else {
                current = null;
                break;
            }
        }

        // Если не нашли в основных, ищем в дефолтных
        if (!current) {
            for (let part of parts) {
                if (defaultCurrent && typeof defaultCurrent === 'object' && defaultCurrent[part] !== undefined) {
                    defaultCurrent = defaultCurrent[part];
                } else {
                    defaultCurrent = null;
                    break;
                }
            }
        }

        // Возвращаем найденный перевод или ключ
        return typeof current === 'string' ? current : typeof defaultCurrent === 'string' ? defaultCurrent : key;
    };

    const contextValue = useMemo(() => ({
        translations, language, setLanguage, translate
    }), [translations, language]);

    return (
        <LanguageContext.Provider value={contextValue}>
            {children}
        </LanguageContext.Provider>
    );
};

async function loadTranslations(language: string) {
    try {
        let response = await fetch(`/translations/${language}.json`);
        return await response.json();
    } catch (error) {
        console.error(`Error loading translations for ${language}:`, error);
        return {};
    }
}

export const useLanguage = (): LanguageContextType => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};