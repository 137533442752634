import React, {useEffect, useState} from "react";
import {Card} from "../../components/shared/card";
import {Input} from "../../components/shared/Input";
import {SearchIcon} from "lucide-react";
import {useLanguage} from "../../contexts/LanguageContext";
import {
    SmsCountryData,
    SmsServiceData,
    transformJsonToSmsCountryList,
    transformJsonToSmsServiceList
} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useToast} from "../../contexts/ToastContext";
import {ItemSmsServiceCardLoader, SmsServiceItem} from "./sms-service-item";
import {Separator} from "../../components/shared/separator";
import {SmsCountryItem} from "./sms-country-item";
import {Button} from "../../components/shared/button";
import {useModal} from "../../hooks/use-modal-store";

export const SmsServicesScene: React.FC = () => {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();
    const {add} = useToast();
    const {isOpen, onOpen, data} = useModal();

    const [search, setSearch] = useState<string>('');
    const [loadingConfigs, setLoadingConfigs] = useState<boolean>(false);
    const [configs, setConfigs] = useState<SmsServiceData[]>([]);
    const [countries, setCountries] = useState<SmsCountryData[]>([]);

    const onClickConfig = (id?: string) => {
        console.log(id);
    }

    const openDeleteCountryModal = (id: string) => {
        onOpen(
            'delete-universal',
            {deleteData: {title: 'Delete?', description: 'Are you sure you want to delete this country?', button_delete: 'Delete', button_close: 'Cancel'}},
            {onConfirm: () => handlerDeleteCountry(id)}
        );
    }

    const handlerDeleteCountry = (id: string) => {
        axiosPrivate.delete(`/sms-services/country/delete?id=${id}`).then((response) => {
            if (response.status === 200) {
                add({type: 'info', message: 'Sms Country is deleted'});
                setCountries(prevState => prevState.filter(country => country.id.toString() !== id))
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const handlerSwitchCountry = (is_active: boolean, id: string) => {
        axiosPrivate.patch(`/sms-services/country/switch?id=${id}&is_active=${is_active}`).then((response) => {
            if (response.status === 200) {
                add({type: 'info', message: 'Sms Country is updated'});
                setCountries(prevState => prevState.map(country => {
                    if (country.id.toString() === id) {
                        country.is_active = is_active;
                    }
                    return country;
                }));
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const loadCountries = () => {
        axiosPrivate.get(`/sms-services/country/list?search=${search}`).then((response) => {
            console.log(response);
            if (response.status === 200) {
                setCountries(transformJsonToSmsCountryList(response.data.data));
            }

            setLoadingConfigs(false)
        }).catch((error) => {
            console.log(error);
            setLoadingConfigs(false);
        });
    }

    useEffect(() => {

        setLoadingConfigs(true);

        axiosPrivate.get(`/sms-services/list?search=${search}`).then((response) => {
            if (response.status === 200) {
                setConfigs(transformJsonToSmsServiceList(response.data.data));
            }

            loadCountries();
        }).catch((error) => {
            console.log(error);
            setLoadingConfigs(false);
        });
    }, [search]);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('sms-service.service.title-page')}</span>

            <Card>
                <div className={"flex flex-row justify-between mb-2"}>
                    <div className={"flex flex-row gap-2"}>
                        <Button className={"w-36"} onClick={onClickConfig}>
                            <span className={"text-xs"}>{translate('sms-service.service.add-new-service')}</span>
                        </Button>

                        <Button className={"w-36 bg-orange/50 hover:bg-orange"} >
                            <span className={"text-xs"}>{translate('sms-service.service.add-new-country')}</span>
                        </Button>
                    </div>
                    <div className={"flex w-52"}>
                        <Input
                            type={"text"}
                            label={translate('def.search')}
                            onChange={(e) => setSearch(e.target.value)}
                            icon={<SearchIcon size={20}/>}
                        />
                    </div>
                </div>

                <Separator className={"mt-2 mb-2"} orientation={"horizontal"}/>

                <span className={"text-lg font-bold text-gray-dark dark:text-white"}>{translate('services')}</span>

                <div
                    className={`grid ${configs.length === 0 && !loadingConfigs ? 'grid-cols-1' : 'lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2'} gap-2`}>
                    {loadingConfigs ? <ItemSmsServiceCardLoader/> : configs.length === 0 ?
                        <span
                            className={"w-full text-center font-bold text-gray-dark dark:text-white p-2"}>{translate('sms-service.service.no_found_sms_services')}</span> :
                        configs.map((config, index) => (
                                <SmsServiceItem key={index} data={config} onClick={onClickConfig}/>
                            )
                        )}
                </div>

                <Separator className={"mt-2 mb-2"} orientation={"horizontal"}/>

                <span className={"text-lg font-bold text-gray-dark dark:text-white"}>{translate('countries')}</span>

                <div
                    className={`grid ${countries.length === 0 && !loadingConfigs ? 'grid-cols-1' : 'lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2'} gap-2`}>
                    {loadingConfigs ? <ItemSmsServiceCardLoader/> : countries.length === 0 ?
                        <span
                            className={"w-full text-center font-bold text-gray-dark dark:text-white p-2"}>{translate('sms-service.service.no_found_sms_countries')}</span> :
                        countries.map((country, index) => (
                                <SmsCountryItem key={index} data={country} onDelete={openDeleteCountryModal} onSwitch={handlerSwitchCountry}/>
                            )
                        )}
                </div>
            </Card>
        </div>
    )
}